import {React, ui, urls} from 'lib';

import {Storage, StockForStorage} from 'types/stock/api/data.gen';
import {Loading, useRemoteData, S3Image, RouterLinkOverlay, EmptyText} from 'shared';

import {StockQuantityForm} from './quantity_form';


export const StockList = React.forwardRef<{
    reload(): void;
}, {
    storage: Storage;
    empty?: string;
}>(({storage, empty}, ref): React.ReactElement => {
    const api = useRemoteData({
        path: 'list_stocks_for_storage',
        request: {storage_id: storage.id},
        reloadWhenFailed: true,
    });

    React.useImperativeHandle(ref, () => ({
        reload: api.reload,
    }));

    if (!api.data) {
        return <Loading />
    }

    if (api.data.items.length === 0) {
        return empty ? <EmptyText>{empty}</EmptyText> : <></>;
    }

    return <ui.Box>
        {api.data.items.map((stock) => <StockListRow
            stock={stock}
            key={stock.id}
        />)}
    </ui.Box>;
});

const StockListRow = ({stock: _stock}: {
    stock: StockForStorage;
}) => {
    const [stock, setStock] = React.useState(_stock);
    React.useEffect(() => {
        setStock(_stock);
    }, [_stock]);

    return <ui.Box borderTop="1px" borderTopColor="gray.300" py={1}>
        <ui.HStack>
            <ui.LinkBox>
                <ui.HStack>
                    <S3Image
                        base={stock.item.icon.base_url}
                        processor="cov128"
                        borderRadius="4px"
                        width="32px"
                        height="32px"
                    />
                    <RouterLinkOverlay to={urls.stock(stock.id)} flexBasis="100%">
                        <ui.Text size="sm">
                            {stock.item.name}
                        </ui.Text>
                    </RouterLinkOverlay>
                </ui.HStack>
            </ui.LinkBox>

            <ui.Spacer />

            <ui.Box flexShrink={0}>
                <StockQuantityForm
                    stock={stock}
                    item={stock.item}
                    onComplete={(s) => setStock(v => ({...v, ...s}))}
                />
            </ui.Box>
        </ui.HStack>
    </ui.Box>;
};
