import {React, ui, urls} from 'lib';

import {User} from 'types/stock/api/data.gen';
import {useSession, useSessionState, SessionRequired, S3Image, RouterLink} from 'shared';
import {LoginRequired} from 'components/account';
import {GenericList} from 'components/generic/list';


export const withFrame = <P, >(Component: React.JSXElementConstructor<P>) => (props: P) => <Frame>
    <Component {...props} />
</Frame>;


export const withLoginExemptFrame = <P, >(Component: React.JSXElementConstructor<P>) => (props: P) => <Frame loginExempt={true}>
    <Component {...props} />
</Frame>;


export const Frame = ({children, sessionExempt, loginExempt}: {
    children: React.ReactNode;
    sessionExempt?: boolean;
    loginExempt?: boolean;
}): React.ReactElement => {
    const session = useSessionState();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <SessionWrapper sessionExempt={sessionExempt}>
        <ui.Box
            position="fixed" top={0} left={0} right={0}
            bgColor="gray.100" zIndex={1001}
            borderBottom="0.5px solid" borderBottomColor="gray.300"
            pt="env(safe-area-inset-top)"
        >
            <ui.Container
                maxW="container.sm"
                height="56px"
            >
                <ui.Stack direction="row" alignItems="center" spacing={3} height="100%">
                    <ui.Box>
                        <ui.Text as={RouterLink} to={urls.home()} fontWeight="bold" fontSize="large">
                            Stock Hub（β）
                        </ui.Text>
                    </ui.Box>
                    <ui.Spacer />
                    {session.ready && session.data?.user && <SessionRequired>
                        <ui.Box>
                            <UserMenu user={session.data.user} />
                        </ui.Box>
                    </SessionRequired>}
                </ui.Stack>
            </ui.Container>
        </ui.Box>

        <ui.Box height="56px" bgColor="white" pt="env(safe-area-inset-top)" />

        <ui.Box py={12} bgColor="white">
            <ui.Container maxW="container.sm">
                <LoginWrapper loginExempt={sessionExempt || loginExempt}>
                    <>{children}</>
                </LoginWrapper>
            </ui.Container>
        </ui.Box>

        <ui.Box
            py={5}
            borderTop="0.5px solid" borderTopColor="gray.300"
        >
            <ui.Container maxW="container.sm">
                <ui.HStack>
                    <ui.Text fontSize="sm" as={RouterLink} to={urls.termsOfService()}>利用規約</ui.Text>
                    <ui.Text fontSize="sm" as={RouterLink} to={urls.privacyPolicy()}>プライバシーポリシー</ui.Text>
                </ui.HStack>
            </ui.Container>
            <ui.Box pb="env(safe-area-inset-bottom)" />
        </ui.Box>
    </SessionWrapper>;
};



const UserMenu = React.memo(({user}: {
    user: User;
}): React.ReactElement => {
    const {isOpen, onOpen, onClose} = ui.useDisclosure()
    const session = useSession();

    const [detail, setDetail] = React.useState<'workspace' | 'team'>();
    const detailDisclosure = ui.useDisclosure({
        isOpen: !!detail,
        onClose: () => setDetail(undefined),
    });

    return <ui.Menu autoSelect={false}>
        <ui.MenuButton display="block">
            <S3Image
                base={user.profile.icon.base_url}
                processor="cov128"
                width="40px"
                height="40px"
                borderRadius="full"
            />
        </ui.MenuButton>
        <ui.MenuList>
            {(session.data?.workspaces.length ?? 0) > 0 && <>
                <ui.MenuGroup title="ワークスペース">
                    {session.data?.workspaces.slice(0, 2).map((workspace) => <ui.MenuItem key={workspace.id} as={RouterLink} to={urls.workspace(workspace.id)}>
                        <S3Image
                            base={workspace.icon.base_url}
                            boxSize="2rem"
                            borderRadius="4px"
                            mr={2}
                        />
                        {workspace.name}
                    </ui.MenuItem>)}
                    {(session.data?.workspaces ?? []).length > 2 && <ui.MenuItem
                        onClick={() => setDetail('workspace')}
                    >
                        すべてを表示
                    </ui.MenuItem>}
                </ui.MenuGroup>
                <ui.MenuDivider />
            </>}
            {(session.data?.teams.length ?? 0) > 0 && <>
                <ui.MenuGroup title="チーム">
                    {session.data?.teams.slice(0, 2).map((team) => <ui.MenuItem
                        key={team.id}
                        as={RouterLink}
                        to={urls.team(team.id)}
                    >
                        <S3Image
                            base={team.profile.icon.base_url}
                            boxSize="2rem"
                            borderRadius="full"
                            mr={2}
                        />
                        {team.profile.name}
                    </ui.MenuItem>)}
                    {(session.data?.teams ?? []).length > 2 && <ui.MenuItem
                        onClick={() => setDetail('team')}
                    >
                        すべてを表示
                    </ui.MenuItem>}
                </ui.MenuGroup>
                <ui.MenuDivider />
            </>}
            <ui.MenuGroup title="アカウント">
                <ui.MenuItem as={RouterLink} to={urls.account()}>
                    <S3Image
                        base={user.profile.icon.base_url}
                        boxSize="2rem"
                        borderRadius="full"
                        mr={2}
                    />
                    {user.profile.name}
                </ui.MenuItem>
                <ui.MenuItem onClick={onOpen}>
                    ログアウト
                </ui.MenuItem>
            </ui.MenuGroup>
        </ui.MenuList>
        <SignoutDialog isOpen={isOpen} onClose={onClose} />

        <ui.Modal {...detailDisclosure}>
            <ui.ModalOverlay />
            <ui.ModalContent>
                <ui.ModalHeader textAlign="center">
                    {{
                        workspace: 'ワークスペース',
                        team: 'チーム',
                    }[detail as string]}
                </ui.ModalHeader>
                <ui.ModalCloseButton />

                <ui.ModalBody>
                    {detail === 'workspace' && <GenericList
                        items={session.data?.workspaces ?? []}
                        linkTo={w => urls.workspace(w.id)}
                        size="md"
                        radius="4px"
                    />}
                    {detail === 'team' && <GenericList
                        items={session.data?.teams ?? []}
                        toGenericItem={t => t.profile}
                        linkTo={t => urls.team(t.id)}
                    />}
                </ui.ModalBody>

                <ui.ModalFooter>
                    <ui.Button onClick={detailDisclosure.onClose} mr={3}>閉じる</ui.Button>
                </ui.ModalFooter>
            </ui.ModalContent>
        </ui.Modal>
    </ui.Menu>;
});


const SignoutDialog = ({isOpen, onClose}: {
    isOpen: boolean;
    onClose(): void;
}) => {
    const {signout, loading} = useSession();
    const cancelRef = React.useRef<HTMLButtonElement>(null)
    const onYes = async () => {
        await signout();
        onClose();
    };
    return <ui.AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
    >
        <ui.AlertDialogOverlay />

        <ui.AlertDialogContent>
            <ui.AlertDialogHeader>ログアウトしますか</ui.AlertDialogHeader>
            <ui.AlertDialogCloseButton />
            <ui.AlertDialogFooter>
                <ui.Button ref={cancelRef} onClick={onClose} isDisabled={loading}>
                    いいえ
                </ui.Button>
                <ui.Button colorScheme="red" ml={3} onClick={onYes} isDisabled={loading}>
                    はい
                </ui.Button>
            </ui.AlertDialogFooter>
        </ui.AlertDialogContent>
    </ui.AlertDialog>;
};


const SessionWrapper = ({children, sessionExempt}: {
    children: React.ReactNode;
    sessionExempt?: boolean;
}) => {
    if (sessionExempt) {
        return <>{children}</>;
    }
    return <SessionRequired children={children} />;
};


const LoginWrapper = ({children, loginExempt}: {
    children: React.ReactNode;
    loginExempt?: boolean;
}) => {
    if (loginExempt) {
        return <>{children}</>;
    }
    return <LoginRequired children={children} />;
};
