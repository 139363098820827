import {React, ui} from 'lib';

import {Workspace, Storage} from 'types/stock/api/data.gen';
import {useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, ImageInput, generateImage, useForm, input} from 'shared';


export const StorageCreationModal = ({
    workspace,
    onComplete,
    onCompleteAndThen,
    ...props
}: {
    workspace: Workspace;
    onComplete(storage: Storage): void;
    onCompleteAndThen?(storage: Storage): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'create_storage';
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        workspace_id: workspace.id,
        icon: generateImage(),
        name: '',
        note: '',
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">保管場所の登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名前"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：リビング、寝室、給湯室"
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="full"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>

                    <FormItem
                        label="メモ"
                        keyPath="note"
                        error={api.error?.data}
                    >
                        <input.Textarea
                            {...binder.mapInputProps('note')}
                            placeholder=""
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    登録
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
            okAndThen="続けて登録する"
            onOkAndThen={onCompleteAndThen ? (data) => {
                onCompleteAndThen?.(data);
                reset();
                api.reset();
            } : undefined}
        />
    </ui.Modal>;
};
