import {React, axios} from 'lib';
import {AxiosError, AxiosResponse} from 'axios';
import {equals, isFunction} from 'utils';

import {Apis} from 'types/stock/api.gen';


type _Apis = Apis[keyof Apis];

export type WriterApis = Extract<_Apis, {writer: true}>;
export type RemoteDatas = Extract<_Apis, {writer: false}>;
export type WriterUrls = WriterApis['url'];
export type ReaderUrls = RemoteDatas['url'];

export type Urls = keyof Apis;
export type Req<U extends Urls> = Apis[U]['Req'];
export type Res<U extends Urls> = Apis[U]['Res'];



axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const axiosPost = <U extends Urls>(
    url: U,
    req: Req<U>,
    sessionKey?: string | null): Promise<AxiosResponse<Res<U>>> => {

    return axios.post(`/${url}`, req, sessionKey ? {
        headers: {'X-Session-Key': sessionKey}
    } : undefined);
}


export type ValidationError = {
    items: {
        message: string;
        code: string;
    }[];
    nested: Keyed<ValidationError>;
};


type RawValidationError = {
    message: string;
    kind: 'validation_failed';
    data: ValidationError;
}

type RawApiError = {
    message: string;
    kind: string;
} | RawValidationError;

export type ApiError = {
    error: AxiosError<RawApiError>;
    message: string | null;
    kind: string | null;
    data?: ValidationError;
}

const constructApiError = (error: AxiosError<RawApiError>): ApiError => {
    const res = error.response?.data;
    if (!res) {
        return {error, message: null, kind: null};
    }
    return {error, ...res};
};


export type State<U extends Urls> = {
    loading: false;
    request: null;
    data: null;
    error: null;
    state: 'waiting';
} | {
    loading: true;
    request: Req<U>;
    data: null;
    error: null;
    state: 'loading';
} | {
    loading: true;
    request: Req<U>;
    data: Res<U>;
    error: null;
    state: 'reloading';
} | {
    loading: false;
    request: Req<U>;
    data: Res<U>;
    error: null;
    state: 'success';
} | {
    loading: false;
    request: Req<U>;
    data: null;
    error: ApiError;
    state: 'failure';
};

type Action<U extends Urls> = {
    type: 'request';
    request: Req<U>;
} | {
    type: 'succeed';
    request: Req<U>;
    data: Res<U>;
} | {
    type: 'update';
    state: State<U>;
} | {
    type: 'fail';
    request: Req<U>;
    error: AxiosError<RawApiError>;
} | {
    type: 'reset';
};


const initialState = <U extends Urls>(): State<U> => ({
    loading: false,
    request: null,
    data: null,
    error: null,
    state: 'waiting',
});


const createReducer = <U extends Urls>() => (state: State<U>, action: Action<U>): State<U> => {
    if (action.type === 'request') {
        if (state.data && equals(state.request, action.request)) {
            return {
                loading: true,
                request: action.request,
                data: state.data,
                error: null,
                state: 'reloading',
            };
        } else {
            return {
                loading: true,
                request: action.request,
                data: null,
                error: null,
                state: 'loading',
            };
        }
    } else if (action.type === 'reset') {
        return {
            loading: false,
            request: null,
            data: null,
            error: null,
            state: 'waiting',
        };
    } else if (action.type === 'update') {
        return action.state;
    } else {
        if (equals(state.request, action.request)) {
            if (action.type === 'succeed') {
                return {
                    loading: false,
                    request: action.request,
                    data: action.data,
                    error: null,
                    state: 'success',
                };
            } else {
                return {
                    loading: false,
                    request: action.request,
                    data: null,
                    error: constructApiError(action.error),
                    state: 'failure',
                };
            }
        }
        return state;
    }
};


export const useMounted = (): {(): boolean} => {
    const mounted = React.useRef(true);
    React.useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);
    return React.useCallback(() => mounted.current, []);
};


const useApiReducer = <U extends Urls>(): [State<U>, {(value: Action<U>): boolean}] => {
    const reducer = React.useMemo(() => createReducer<U>(), []);
    const [state, _dispatch] = React.useReducer(reducer, initialState<U>());
    const mounted = useMounted();
    const dispatch = React.useCallback((action: Action<U>): boolean => {
        if (mounted()) {
            _dispatch(action);
            return true;
        }
        return false;
    }, [_dispatch, mounted]);
    return [state, dispatch];
};


export type RemoteData<U extends ReaderUrls> = {
    setData(data: Res<U>): void;
    reload(): Promise<Res<U> | null>;
    reset(): void;
} & State<U>


export const useRemoteData = <U extends ReaderUrls>({
    path,
    request,
    halt = false,
    reloadWhenFailed = true,
    onClientError,
}: {
    path: U;
    request: Req<U>;
    halt?: boolean;
    reloadWhenFailed?: boolean;
    onClientError?(error: AxiosError<RawApiError>): void;
}): RemoteData<U> => {
    const sessionKey = useSessionKey();
    const [state, dispatch] = useApiReducer<U>();

    const encodedRequest = JSON.stringify(request);

    const ref = React.useRef({sessionKey, reloadWhenFailed, onClientError});
    ref.current = {sessionKey, reloadWhenFailed, onClientError};

    const call = React.useCallback(() => {
        const request = JSON.parse(encodedRequest) as Req<U>;
        const {sessionKey} = ref.current;
        const call = async (): Promise<Res<U> | null> => {
            if (halt) {
                dispatch({type: 'reset'});
                return null;
            }
            try {
                const response = await axiosPost(path, request, sessionKey);
                const {data} = response;
                dispatch({type: 'succeed', request, data});
                return data;
            } catch (error) {
                const status = error.response?.status ?? 400;
                if (error.response && status >= 400 && status < 500) {
                    ref.current?.onClientError?.(error);
                }
                if (status >= 500 && ref.current.reloadWhenFailed) {
                    await new Promise((r) => setTimeout(r, 3000));
                    return await call()
                } else {
                    dispatch({type: 'fail', request, error});
                }
                throw error;
            }
        };
        dispatch({type: 'request', request});
        return call();
    }, [dispatch, path, encodedRequest, halt]);

    React.useEffect(() => {
        if (sessionKey) {
            call();
        }
    }, [call, sessionKey]);

    const setData = React.useCallback(
        (data: Res<U>) => {
            if (state.state === 'success' || state.state === 'reloading') {
                dispatch({type: 'update', state: {...state, data}});
            }
        },
        [state, dispatch]);
    const reset = React.useCallback(() => dispatch({type: 'reset'}), [dispatch]);

    return React.useMemo(() => ({
        reload: call,
        setData,
        reset,
        ...state
    }), [call, setData, reset, state]);
};



export type Api<U extends Urls> = {
    isMounted(): boolean;
    setData(data: Res<U>): void;
    call(request: Req<U>): Promise<Res<U>>;
    reset(): void;
} & State<U>;


export const useApi = <U extends Urls>(path: U): Api<U> => {
    const sessionKey = useSessionKey();
    const [state, dispatch] = useApiReducer<U>();

    const ref = React.useRef({sessionKey});
    ref.current = {sessionKey};

    const isMounted = useMounted();

    const call = React.useCallback((request) => {
        const {sessionKey} = ref.current;
        const call = async (): Promise<Res<U>> => {
            dispatch({type: 'request', request});
            try {
                const response = await axiosPost(path, request, sessionKey);
                const {data} = response;
                dispatch({type: 'succeed', request, data});
                return data;
            } catch (error) {
                dispatch({type: 'fail', request, error});
                throw error;
            }
        };
        return call();
    }, [dispatch, path]);

    const setData = React.useCallback(
        (data: Res<U>) => {
            if (!isMounted()) {
                return;
            }
            if (state.state === 'success' || state.state === 'reloading') {
                dispatch({type: 'update', state: {...state, data}});
            }
        },
        [state, dispatch, isMounted]);
    const reset = React.useCallback(() => dispatch({type: 'reset'}), [dispatch]);

    return React.useMemo(() => ({
        setData,
        call,
        reset,
        isMounted,
        ...state,
    }), [call, setData, reset, isMounted, state]);
};


type SessionData = Res<'get_session'>;

type UninitializedSession = {
    ready: false;
    loading: boolean;
    init(): Promise<void>;
};

export type Session = {
    ready: true;
    key: string;
    data: SessionData;
    loading: boolean;
    reload(): Promise<void>;
    signout(): Promise<void>;
}

type SessionState = UninitializedSession | Session;

const SessionStateContext = React.createContext<SessionState | null>(null);

export const SessionProvider = ({children}: {children: React.ReactNode}) => {
    const KEY = '_session_key';
    const initialSsessionKey = React.useMemo(() => localStorage.getItem(KEY), []);
    const [sessionKey, setSessionKey] = React.useState<string | null>(initialSsessionKey);
    const [data, setData] = React.useState<SessionData>();
    const [loading, setLoading] = React.useState(false);

    const destroySessionkey = React.useCallback(() => {
        localStorage.removeItem(KEY);
        setSessionKey(null);
    }, [setSessionKey]);

    const initSession = React.useCallback(() => {
        return (async () => {
            setLoading(true);
            const {data: {key, data}} = await axiosPost('init_session', {});
            localStorage.setItem(KEY, key);
            setSessionKey(key);
            setData(data);
            setLoading(false);
        })();
    }, [setSessionKey]);

    const getSession = React.useCallback((key: string) => {
        return (async () => {
            setLoading(true);
            try {
                const {data} = await axiosPost('get_session', {}, key);
                setData(data);
            } catch(e) {
                if (e.response?.status === 401) {
                    destroySessionkey();
                    return await initSession();
                } else if ([502, 504].includes(e.response?.status)) {
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    await getSession(key);
                }
            }
            setLoading(false);
        })();
    }, [setData, initSession, destroySessionkey]);

    const reload = React.useCallback(async () => {
        if (sessionKey) {
            return await getSession(sessionKey);
        }
    }, [sessionKey, getSession]);

    const signout = React.useCallback(() => {
        return (async () => {
            setLoading(true);
            try {
                const {data} = await axiosPost('signout', {}, sessionKey);
                setData(data);
            } catch(e) {
                if (e.response?.status === 401) {
                    destroySessionkey();
                }
            }
            setLoading(false);
        })();
    }, [sessionKey, setData, destroySessionkey]);

    const session: SessionState = React.useMemo(() => {
        if (!sessionKey || !data) {
            return {
                ready: false,
                loading: loading,
                init: initSession,
            };
        } else {
            return {
                ready: true,
                key: sessionKey,
                data,
                loading: loading,
                reload,
                signout,
            };
        }
    }, [sessionKey, data, loading, initSession, reload, signout]);

    React.useEffect(() => {
        if (initialSsessionKey && !data) {
            getSession(initialSsessionKey);
        }
    }, [initialSsessionKey, data, getSession]);

    if (initialSsessionKey && !data) {
        return null;
    }

    return <SessionStateContext.Provider
        value={session}
        children={children}
    />;
};


export type ReaderApi<U extends ReaderUrls> = Api<U>;
export const useReaderApi = <U extends ReaderUrls>(path: U): ReaderApi<U> => useApi(path);

export type WriterApi<U extends WriterUrls> = Api<U>;
export const useWriterApi = <U extends WriterUrls>(path: U): WriterApi<U> => useApi(path);


const SessionRequiredContext = React.createContext(false);

export const SessionRequired = ({children}: {
    children: React.ReactNode | {(session: Session): React.ReactNode};
}): React.ReactElement | null => {
    const state = useSessionState();

    React.useEffect(() => {
        if (!state.ready && !state.loading) {
            state.init();
        }
    }, [state]);

    if (!state.ready) {
        return null;
    }

    return <SessionRequiredContext.Provider value={true}>
        {isFunction(children) ? children(state) : children}
    </SessionRequiredContext.Provider>;
};


export const useSessionKey = (): string | null => {
    const state = React.useContext(SessionStateContext);
    return state?.ready ? state.key : null;
};


export const useSessionState = (): SessionState => {
    const session = React.useContext(SessionStateContext);
    if (!session) {
        throw new Error('Can not call `useSessionState` outside out SessionProvider');
    }
    return session;
}


export const useSession = (): Session => {
    const required = React.useContext(SessionRequiredContext);
    const session = React.useContext(SessionStateContext);
    if (!required || !session || !session.ready) {
        throw new Error('Can not call `useSession` outside out SessionRequired');
    }
    return session;
}


export const useSessionData = (): SessionData => {
    return useSession().data;
}
