import {React, ui, icons} from 'lib';

import {Item} from 'types/stock/api/data.gen';
import {S3Image, useRemoteData, LayoutItem, Spinner, InputProps} from 'shared';
import {useWorkspace} from 'components/workspace/context';
import {ItemList} from './list';
import {ItemCreationModal} from './creation';


export const ItemIdInput = ({
    value,
    onChange,
}: InputProps<string | null | undefined, string>): React.ReactElement | null => {
    const {data: item} = useRemoteData({
        path: 'preview_item',
        request: {item_id: value ?? ''},
        halt: !value,
    });

    if (value && value !== item?.id) {
        return <Spinner size="lg" />
    }

    return <ItemInput
        value={item}
        onChange={(e, v) => onChange(e, v.id)}
    />;
};


export const ItemInput = ({
    value,
    onChange,
}: InputProps<Item | null | undefined, Item>): React.ReactElement => {
    const selectDisclosure = ui.useDisclosure();
    const workspace = useWorkspace();

    return <>
        <ui.HStack>
            <S3Image
                base={value?.icon.base_url}
                processor="cov128"
                borderRadius="4px"
                width="44px"
                height="44px"
                mr={2}
            />

            <ui.Box flexBasis="100%">
                <ui.Text fontSize="xs" mr={1}>
                    {workspace.name}
                </ui.Text>

                {value && <ui.Text fontSize="md">
                    {value.name}
                </ui.Text>}

                {!value && <ui.Text fontSize="md" color="gray.500">
                    未選択
                </ui.Text>}
            </ui.Box>

            <ui.Button onClick={selectDisclosure.onOpen}>
                {value ? '変更' : '設定'}
            </ui.Button>
        </ui.HStack>

        <ItemSelectModal
            value={value}
            onChange={(e, p) => {
                selectDisclosure.onClose();
                onChange(e, p);
            }}
            {...selectDisclosure}
        />
    </>;
};



export const ItemSelectModal = ({
    value,
    onChange,
    ...props
}: InputProps<Item | null | undefined, Item> & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const [item, setItem] = React.useState<Item>();
    React.useEffect(() => {
        if (props.isOpen) {
            setItem(value ?? undefined);
        }
    }, [props.isOpen, value]);
    const workspace = useWorkspace();
    const listRef = React.useRef<Ref<typeof ItemList>>(null);
    const disclosure = ui.useDisclosure();

    return <ui.Modal {...props} size="md">
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">アイテムを選択</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <LayoutItem>
                    <ui.Button
                        colorScheme="green"
                        onClick={disclosure.onOpen}
                        leftIcon={<icons.AddIcon />}
                    >
                        新規
                    </ui.Button>
                </LayoutItem>

                <LayoutItem.Group space={4}>
                    <LayoutItem>
                        <ItemList
                            ref={listRef}
                            workspace={workspace}
                            selected={item?.id}
                            onSelect={(p) => setItem(p)}
                            empty={item ? undefined : `${workspace.name}にはアイテムが登録されていません`}
                            onCreated={(item) => {
                                listRef.current?.reload();
                                onChange(null, item);
                            }}
                        />
                    </LayoutItem>
                </LayoutItem.Group>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={(e) => item && onChange(e, item)}
                    isDisabled={!item}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ItemCreationModal
            workspace={workspace}
            omitQuantities={true}
            {...disclosure}
            onComplete={(item) => {
                listRef.current?.reload();
                onChange(null, item);
            }}
        />
    </ui.Modal>;
};

