import {React, ui, icons, urls, Router} from 'lib';

import {LayoutItem, useSession, Section} from 'shared';
import {withLoginExemptFrame} from 'components/frame';
import {useUser, LoginRequired} from 'components/account';
import {GenericList} from 'components/generic/list';
import {WorkspaceCreationModal} from 'components/workspace/creation';
import {TeamCreationModal} from 'components/team/creation';


export const Home = withLoginExemptFrame((): React.ReactElement => {
    const session = useSession();
    const [login, setLogin] = React.useState(false);

    const isLoggedIn = !!session.data?.user;

    if (!isLoggedIn && login) {
        return <LoginRequired>
        </LoginRequired>;
    }

    return <>
        <Section>
            <LayoutItem.Group space={4}>
                <LayoutItem>
                    <ui.Heading size="xl">
                        ようこそ
                    </ui.Heading>
                </LayoutItem>

                <LayoutItem>
                    <ui.Text>
                        Stock Hub（β） は在庫管理サービスです<br />
                        プライベートからビジネスまであらゆる在庫の管理にご利用いただけます<br />
                        まずは日用品の管理から初めてみましょう
                    </ui.Text>
                </LayoutItem>
            </LayoutItem.Group>
        </Section>

        {!isLoggedIn && <Section>
            <ui.Button onClick={() => setLogin(true)} colorScheme="blue">
                無料で利用する
            </ui.Button>
        </Section>}

        {isLoggedIn && <LoginRequired>
            <WorkspaceSection />
            <TeamSection />
        </LoginRequired>}
    </>
});


const WorkspaceSection = () => {
    const history = Router.useHistory();
    const session = useSession();
    const creationDisclosure = ui.useDisclosure();
    const user = useUser();

    return <>
        <Section
            title="ワークスペース"
            titleRightAccessory={<ui.Button
                colorScheme="blue"
                onClick={creationDisclosure.onOpen}
                leftIcon={<icons.AddIcon />}
                size="sm"
            >
                追加
            </ui.Button>}
            description="在庫はワークスペースごとに管理されます。プライベートや仕事などで使い分ける場合はそれぞれのワークスペースを作成してください。"
        >
            <GenericList
                items={session.data.workspaces}
                linkTo={w => urls.workspace(w.id)}
                empty="ワークスペースが登録されていません"
                size="md"
                radius="4px"
            />
        </Section>

        <WorkspaceCreationModal
            {...creationDisclosure}
            owner={user.profile}
            onComplete={(workspace) => {
                session.reload();
                history.push(urls.workspace(workspace.id));
                creationDisclosure.onClose();
            }}
        />
    </>
};

const TeamSection = () => {
    const history = Router.useHistory();
    const session = useSession();
    const creationDisclosure = ui.useDisclosure();

    return <>
        <Section
            title="チーム"
            titleRightAccessory={<ui.Button
                onClick={creationDisclosure.onOpen}
                leftIcon={<icons.AddIcon />}
                size="sm"
            >
                追加
            </ui.Button>}
            description="複数人で複数のワークスペースを管理する場合はチームを作成すると便利です。"
        >
            <GenericList
                items={session.data.teams}
                toGenericItem={t => t.profile}
                linkTo={t => urls.team(t.id)}
                empty="チームが登録されていません"
            />
        </Section>

        <TeamCreationModal
            {...creationDisclosure}
            onComplete={(team) => {
                session.reload();
                history.push(urls.team(team.id));
                creationDisclosure.onClose();
            }}
        />
    </>
};
