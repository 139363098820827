import {React, ui} from 'lib';

import {useSessionData, InputProps} from 'shared';
import {Profile} from 'types/stock/api/data.gen';


const useProfiles = (excludeUser?: boolean): Profile[] => {
    const {user, teams} = useSessionData();
    return React.useMemo(() => {
        const profiles = user && !excludeUser ? [user.profile] : [];
        return [...profiles, ...teams.map(t => t.profile)];
    }, [excludeUser, user, teams]);
};


export const ProfileIdInput = ({
    value,
    onChange,
    excludeUser,
}: {
    excludeUser?: boolean;
} & InputProps<string | undefined, string>): React.ReactElement | null => {
    const profiles = useProfiles();
    const profile = React.useMemo(() => {
        return profiles.filter(p => p.id === value)[0];
    }, [profiles, value]);

    return <ProfileInput
        value={profile}
        onChange={(e, p) => onChange(e, p.id)}
        excludeUser={excludeUser}
    />;
};


export const ProfileInput = ({
    value,
    onChange,
    excludeUser,
}: {
    excludeUser?: boolean;
} & InputProps<Profile | undefined, Profile>): React.ReactElement => {
    const profiles = useProfiles(excludeUser);

    return <>
        <ui.Select
            value={value?.id}
            onChange={(e) => {
                const id = e.target.value;
                onChange(e, profiles.filter(p => p.id === id)[0]);
            }}
        >
            {!value && <option>選択してください</option>}
            {profiles.map((p) => <option
                key={p.id}
                value={p.id}
            >
                {p.name}
            </option>)}
        </ui.Select>
    </>;
};
