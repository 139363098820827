import {React, ui, urls} from 'lib';

import {Item, StockForItem} from 'types/stock/api/data.gen';
import {Loading, useRemoteData, S3Image, RouterLinkOverlay} from 'shared';

import {StockQuantityForm} from './quantity_form';


export const StockList = React.forwardRef<{
    reload(): void;
}, {
    item: Item;
    empty?: React.ReactNode;
}>(({item, empty}, ref): React.ReactElement => {
    const api = useRemoteData({
        path: 'list_stocks_for_item',
        request: {item_id: item.id},
        reloadWhenFailed: true,
    });

    React.useImperativeHandle(ref, () => ({
        reload: api.reload,
    }));

    if (!api.data) {
        return <Loading />
    }

    if (api.data.items.length === 0) {
        return <>{empty}</>
    }

    return <ui.Box>
        {api.data.items.map((stock) => <StockListRow
            stock={stock}
            item={item}
            key={stock.id}
        />)}
    </ui.Box>;
});

const StockListRow = ({stock: _stock, item}: {
    stock: StockForItem;
    item: Item;
}) => {
    const [stock, setStock] = React.useState(_stock);
    React.useEffect(() => {
        setStock(_stock);
    }, [_stock]);

    return <ui.Box borderTop="1px" borderTopColor="gray.300" py={1}>
        <ui.HStack>
            <ui.LinkBox>
                <ui.HStack>
                    <S3Image
                        base={stock.storage.icon.base_url}
                        processor="cov128"
                        borderRadius="full"
                        width="32px"
                        height="32px"
                    />
                    <RouterLinkOverlay to={urls.stock(stock.id)} flexBasis="100%">
                        <ui.Text size="sm">
                            {stock.storage.name}
                        </ui.Text>
                    </RouterLinkOverlay>
                </ui.HStack>
            </ui.LinkBox>

            <ui.Spacer />

            <ui.Box flexShrink={0}>
                <StockQuantityForm
                    stock={stock}
                    item={item}
                    onComplete={(s) => setStock(v => ({...v, ...s}))}
                />
            </ui.Box>
        </ui.HStack>
    </ui.Box>;
};
