import {React, Router, RouterDom, ReactDOM, Recoil, ui, urls} from 'lib';

import {SessionProvider, ProvideClient} from 'shared';
import {Account, CommitEmailChange, PasswordReset} from 'account';
import {Home} from 'home';
import {ItemDetail} from 'item';
import {StorageDetail} from 'storage';
import {StockDetail} from 'stock';
import {TeamDetail, TeamInvitation} from 'team';
import {WorkspaceDetail, WorkspaceInvitation} from 'workspace';
import {Signup} from 'signup';
import {TermsOfService} from 'terms_of_service';
import {PrivacyPolicy} from 'privacy_policy';


const Index = (): React.ReactElement => {
    return <Recoil.RecoilRoot>
        <RouterDom.BrowserRouter>
            <Router.Switch>
                <Router.Route
                    path={urls.signup(':token?')}
                    render={({match}) => <Signup token={match.params.token} />}
                />
                <Router.Route
                    path={urls.teamInvitation(':token([^/]+)') as ':token'}
                    render={({match}) => <TeamInvitation
                        token={match.params.token}
                        key={match.params.token}
                    />}
                />
                <Router.Route
                    path={urls.team(':team_id([^/]+)') as ':team_id'}
                    render={({match}) => <TeamDetail
                        teamId={match.params.team_id}
                        key={match.params.team_id}
                    />}
                />
                <Router.Route
                    path={urls.workspaceInvitation(':token([^/]+)') as ':token'}
                    render={({match}) => <WorkspaceInvitation
                        token={match.params.token}
                        key={match.params.token}
                    />}
                />
                <Router.Route
                    path={urls.workspace(':workspace_id([^/]+)') as ':workspace_id'}
                    render={({match}) => <WorkspaceDetail
                        workspaceId={match.params.workspace_id}
                        key={match.params.workspace_id}
                    />}
                />
                <Router.Route
                    path={urls.storage(':storage_id([^/]+)') as ':storage_id'}
                    render={({match}) => <StorageDetail
                        storageId={match.params.storage_id}
                        key={match.params.storage_id}
                    />}
                />
                <Router.Route
                    path={urls.item(':item_id([^/]+)') as ':item_id'}
                    render={({match}) => <ItemDetail
                        itemId={match.params.item_id}
                        key={match.params.item_id}
                    />}
                />
                <Router.Route
                    path={urls.stock(':stock_id([^/]+)') as ':stock_id'}
                    render={({match}) => <StockDetail
                        stockId={match.params.stock_id}
                        key={match.params.stock_id}
                    />}
                />
                <Router.Route
                    path={urls.commitEmailChange(':token([^/]+)') as ':token'}
                    render={({match}) => <CommitEmailChange
                        token={match.params.token}
                        key={match.params.token}
                    />}
                />
                <Router.Route
                    path={urls.passwordReset(':token([^/]+)') as ':token'}
                    render={({match}) => <PasswordReset
                        token={match.params.token}
                        key={match.params.token}
                    />}
                />
                <Router.Route path={urls.account()} component={Account} />
                <Router.Route path={urls.termsOfService()} component={TermsOfService} />
                <Router.Route path={urls.privacyPolicy()} component={PrivacyPolicy} />
                <Router.Route path="/" component={Home} />
            </Router.Switch>
        </RouterDom.BrowserRouter>
    </Recoil.RecoilRoot>
}

const theme = ui.extendTheme({
    styles: {
        global: {
            'html, body': {
                height: '100vh',
                backgroundColor: 'gray.100',
                lineHeight: 'tall',
            },
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ui.ChakraProvider
            theme={theme}
        >
            <SessionProvider>
                <ProvideClient>
                    <Index />
                </ProvideClient>
            </SessionProvider>
        </ui.ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
