import {React, ui, icons, Router, urls} from 'lib';

import {Team, TeamMember} from 'types/stock/api/data.gen';
import {Section, LoadingScreen, Loading, SimpleCenteredContent, useRemoteData, LayoutItem, S3Image, S3ImageWithPreview, RouterTextLink, useSession, useSessionState, useWriterApi, useForm, input, ImageInput, imgurl, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, AlertDialog, InputProps} from 'shared';
import {WorkspaceList} from 'components/workspace/list';
import {WorkspaceCreationModal} from 'components/workspace/creation';
import {withFrame} from 'components/frame';


export const TeamDetail = withFrame(({teamId}: {
    teamId: string;
}): React.ReactElement => {
    const session = useSession();
    const history = Router.useHistory();
    const moveToHome = () => history.replace(urls.top());
    const api = useRemoteData({
        path: 'get_team',
        request: {team_id: teamId},
        reloadWhenFailed: true,
        onClientError: moveToHome,
    });
    const editionDisclosure = ui.useDisclosure();
    const invitationDisclosure = ui.useDisclosure();
    const workspaceCreationDisclosure = ui.useDisclosure();
    const workspaceListRef = React.useRef<Ref<typeof WorkspaceList>>(null);

    if (api.state !== 'success') {
        return <LoadingScreen />
    }
    const {team, memberships} = api.data;

    return <>
        <Section>
            <LayoutItem>
                <ui.HStack>
                    <ui.Text>
                        <RouterTextLink to={urls.home()}>HOME</RouterTextLink>
                    </ui.Text>
                </ui.HStack>
            </LayoutItem>

            <LayoutItem.Group space={4}>
                <LayoutItem>
                    <ui.Box>
                        <ui.HStack width="100%">
                            <S3ImageWithPreview
                                base={team.profile.icon.base_url}
                                processor="cov128"
                                borderRadius="full"
                                width="64px"
                                height="64px"
                                mr={2}
                                previewTitle={team.profile.name}
                            />

                            <ui.Heading size="xl">
                                {team.profile.name}
                            </ui.Heading>

                            <ui.Spacer />

                            <ui.IconButton
                                onClick={editionDisclosure.onOpen}
                                icon={<icons.EditIcon />}
                                aria-label="edit-team"
                            />
                        </ui.HStack>
                    </ui.Box>
                </LayoutItem>

                <LayoutItem>
                    <ui.HStack>
                        <ui.Button
                            colorScheme="blue"
                            onClick={invitationDisclosure.onOpen}
                            leftIcon={<icons.AddIcon />}
                        >
                            メンバーを追加
                        </ui.Button>
                    </ui.HStack>
                </LayoutItem>
            </LayoutItem.Group>
        </Section>

        <MemberSection team={team} memberships={memberships} />

        <Section
            title="ワークスペース"
            titleRightAccessory={<ui.Button
                onClick={workspaceCreationDisclosure.onOpen}
                leftIcon={<icons.AddIcon />}
                size="sm"
            >
                追加
            </ui.Button>}
        >
            <WorkspaceList
                ref={workspaceListRef}
                profileIds={[team.profile.id]}
                linkTo={(workspace) => urls.workspace(workspace.id)}
                empty={`${team.profile.name}にはワークスペースが登録されていません`}
                size="md"
            />
        </Section>

        <TeamEditionModal
            {...editionDisclosure}
            team={team}
            onComplete={(team) => {
                api.setData({team, memberships});
                editionDisclosure.onClose();
            }}
            onDeleted={() => history.replace(urls.home())}
        />

        <TeamMemberInvitationModal
            {...invitationDisclosure}
            memberships={memberships}
            team={team}
            onComplete={() => {
                invitationDisclosure.onClose()
            }}
        />

        <WorkspaceCreationModal
            {...workspaceCreationDisclosure}
            owner={team.profile}
            onComplete={(workspace) => {
                session.reload();
                history.push(urls.workspace(workspace.id));
                workspaceCreationDisclosure.onClose();
            }}
        />
    </>
});


const TeamEditionModal = ({
    team,
    onComplete,
    onDeleted,
    ...props
}: {
    team: Team;
    onComplete(team: Team): void;
    onDeleted(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_team';
    const session = useSession();
    const api = useWriterApi(path);
    const deleteApi = useWriterApi('delete_team');

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        team_id: team.id,
        icon: imgurl(team.profile.icon.base_url, 'cov128'),
        name: team.profile.name,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">チームの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名前"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：家族"
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="full"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={async (data) => {
                await session.reload();
                onComplete(data);
                reset();
                api.reset();
            }}
        />

        <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={() => deleteApi.call({team_id: team.id}).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text>
                    この操作は取り消せません。<br />
                    本当に削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>
        <ApiSpinnerDialog api={deleteApi} />
        <ApiCompletionDialog
            message="データを削除しました"
            api={deleteApi}
            onOk={async () => {
                await session.reload();
                onDeleted();
            }}
        />
    </ui.Modal>;
};




const MemberSection = ({team, memberships}: {
    team: Team;
    memberships: TeamMember[];
}) => {
    const listRef = React.useRef<Ref<typeof MemberList>>(null);
    const [member, setMember] = React.useState<TeamMember>();
    const memberDisclosure = ui.useDisclosure({
        isOpen: !!member,
        onClose: () => setMember(undefined),
    });

    return <Section title="メンバー">
        <MemberList
            ref={listRef}
            team={team}
            onClick={setMember}
        />

        <TeamMemberModal 
            {...memberDisclosure}
            team={team}
            memberships={memberships}
            member={member}
            onComplete={() => {
                listRef.current?.reload();
                setMember(undefined);
            }}
            onRemoved={() => {
                listRef.current?.reload();
                setMember(undefined);
            }}
        />
    </Section>
}

const MemberList = React.forwardRef<{
    reload(): void;
}, {
    team: Team;
    onClick(member: TeamMember): void;
}>(({team, onClick}, ref) => {
    const api = useRemoteData({
        path: 'get_team_members',
        request: {team_id: team.id},
        reloadWhenFailed: true,
    });
    React.useImperativeHandle(ref, () => ({
        reload: () => api.reload(),
    }));
    if (api.state !== 'success') {
        return <Loading />;
    }
    return <ui.Box>
        <ui.AvatarGroup>
            {api.data.items.map((m) => <ui.Avatar
                key={m.id}
                src={imgurl(m.user.profile.icon.base_url, 'cov128')}
                onClick={() => onClick(m)}
                cursor="pointer"
            />)}
        </ui.AvatarGroup>
    </ui.Box>;
});


const TeamMemberInvitationModal = ({
    memberships,
    team,
    onComplete,
    ...props
}: {
    memberships: TeamMember[];
    team: Team;
    onComplete(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'send_team_invitation';
    const api = useWriterApi(path);

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        team_id: team.id,
        email: '',
        role: 'member',
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">メンバーの追加</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="メールアドレス"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <input.Input
                            type="email"
                            placeholder="email"
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="権限"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        <RoleInput
                            owner={memberships.some(m => m.role === 'owner')}
                            {...binder.mapInputProps('role')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    招待を送る
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={async () => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const TeamMemberModal = ({
    team,
    memberships,
    member,
    onComplete,
    onRemoved,
    ...props
}: {
    team: Team;
    memberships: TeamMember[],
    member: TeamMember | undefined;
    onComplete(member: TeamMember): void;
    onRemoved(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_team_member';
    const api = useWriterApi(path);
    const removeApi = useWriterApi('remove_team_member');

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>, keyof Req<typeof path>>({
        team_id: member?.team_id,
        member_id: member?.id,
        role: member?.role,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    const roles = memberships.filter(m => m.id !== member?.id).map(m => m.role);
    const editable = roles.includes('owner') || (roles.includes('admin') && member?.role !== 'owner');

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">メンバー情報</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <LayoutItem>
                        <ui.HStack width="100%">
                            <S3Image
                                base={member?.user.profile.icon.base_url}
                                processor="cov128"
                                borderRadius="full"
                                width="44px"
                                height="44px"
                                mr={2}
                            />

                            <ui.Text>
                                {member?.user.profile.name}
                            </ui.Text>
                        </ui.HStack>
                    </LayoutItem>

                    <FormItem
                        label="権限"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        <RoleInput
                            owner={roles.includes('owner')}
                            isDisabled={!editable}
                            {...binder.mapInputProps('role')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button
                    colorScheme="red"
                    onClick={confirmDisclosure.onOpen}
                    isDisabled={!editable}
                >
                    除外
                </ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                    isDisabled={!editable}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={async (data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />

        {member && <AlertDialog
            {...confirmDisclosure}
            title="除外の確認"
            confirm="除外する"
            onConfirm={() => removeApi.call({
                team_id: team.id,
                member_id: member.id,
            }).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text color="red">
                    {member?.user.profile.name}を{team.profile.name}のメンバーから外します
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    本当に除外してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>}
        <ApiSpinnerDialog api={removeApi} />
        <ApiCompletionDialog
            message="メンバーを解除しました"
            api={removeApi}
            onOk={onRemoved}
        />
    </ui.Modal>;
};


export const TeamInvitation = withFrame(({token}: {
    token: string;
}) => {
    const history = Router.useHistory();
    const session = useSessionState();
    const {loading, data: team} = useRemoteData({
        path: 'retrieve_team_from_invitation',
        request: {token},
    });

    const path = 'accept_team_invitation';
    const api = useWriterApi(path);
    const submit = async () => {
        const data = await api.call({token});
        if (data && session.ready) {
            session.reload();
            history.push(urls.team(data.id));
        }
    };

    React.useEffect(() => {
        if (team && session.ready && session.data.teams.find(w => w.id === team.id)) {
            history.replace(urls.team(team.id));
        }
    }, [history, session, team]);

    if (loading) {
        return <LoadingScreen />
    }

    if (!team) {
        return <SimpleCenteredContent>
            <ui.VStack spacing={5}>
                <ui.Text color="red.500">
                    URLが間違っているか有効期限切れです
                </ui.Text>

                <ui.Button onClick={() => history.replace('/')}>
                    トップページに戻る
                </ui.Button>
            </ui.VStack>
        </SimpleCenteredContent>;
    }

    return <SimpleCenteredContent title="チームに招待されています">
        <ui.VStack spacing={5}>
            <ui.HStack width="100%">
                <S3Image
                    base={team.profile.icon.base_url}
                    processor="cov128"
                    borderRadius="full"
                    width="64px"
                    height="64px"
                    mr={2}
                />

                <ui.Text>
                    チーム「{team.profile.name}」に招待されています。
                </ui.Text>
            </ui.HStack>

            <ui.Button onClick={submit} width="100%" isDisabled={api.loading || session.loading}>
                承諾
            </ui.Button>
        </ui.VStack>
    </SimpleCenteredContent>;
});



type Role = TeamMember['role'];

const RoleInput = ({value, onChange, owner, isDisabled = false}: {
    owner: boolean;
    isDisabled?: boolean;
} & InputProps<Role | undefined, Role>) => {
    const choices = React.useMemo(() => [
        {value: 'owner', label: 'オーナー'},
        {value: 'admin', label: '管理者'},
        {value: 'member', label: 'メンバー'},
    ], []);
    return <ui.RadioGroup onChange={(v) => onChange(null, v as Role)} value={value}>
        <ui.Stack direction="row">
            {choices.map(({value, label}) => <ui.Radio
                value={value}
                isDisabled={isDisabled || (value === 'owner' && !owner)}
                key={value}
            >
                {label}
            </ui.Radio>)}
        </ui.Stack>
    </ui.RadioGroup>
} 
