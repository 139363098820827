import {React, ui} from 'lib';

import {S3Image, RouterLinkOverlay, EmptyText} from 'shared';
import {Image} from 'types/stock/api/data.gen';


export type GenericItem = {
    id: string;
    name: string;
    icon: Image;
};


export type GenericListPropsButItems<T> = {
    selected?: string | string[];
    empty?: string;
    spacing?: ui.StackProps['spacing'];
    width?: ui.BoxProps['width'];
} & ({
    onSelect(item: T): void;
} | {
    linkTo(item: T): string;
});


export type DefaultGenericListProps<T> = {
    radius?: ui.BoxProps['borderRadius'];
    size?: 'sm' | 'md';
} & GenericListPropsButItems<T>;


type ItemComponentProps<T> = {
    item: T;
    isSelected: boolean;
}
type ItemComponent<T> = React.JSXElementConstructor<ItemComponentProps<T>>;


export function GenericList<T>(props: {
    items: T[];
    toGenericItem(item: T): GenericItem;
} & DefaultGenericListProps<T>): React.ReactElement;
export function GenericList<T extends GenericItem>(props: {
    items: T[];
} & DefaultGenericListProps<T>): React.ReactElement;
export function GenericList<T>(props: {
    items: T[];
    as: ItemComponent<T>;
} & GenericListPropsButItems<T>): React.ReactElement;
export function GenericList<T, >({
    items,
    selected,
    empty,
    toGenericItem = (item: T) => item as unknown as GenericItem,
    as: Component,
    radius = 'full',
    size = 'sm',
    spacing = 2,
    width,
    onSelect,
    linkTo,
}: DefaultGenericListProps<T> & {
    items: T[];
    toGenericItem?(item: T): GenericItem;
    as?: ItemComponent<T>
    onSelect?(item: T): void;
    linkTo?(item: T): string;
}): React.ReactElement {
    if (items.length === 0) {
        return empty ? <EmptyText>{empty}</EmptyText> : <></>;
    }

    const iconSize = {
        sm: '36px',
        md: '44px',
    }[size];

    const _selected = selected
        ? typeof selected === 'string'
            ? [selected]
            : selected
        : [];

    const negativeMarign = typeof spacing === 'number' ? -spacing : `-${spacing}`;

    return <ui.Box overflow="hidden">
        <ui.Flex
            flexWrap="wrap"
            mt={negativeMarign}
            ml={negativeMarign}
        >
            {items.map((item, i) => {
                const genericItem = toGenericItem(item);
                const isSelected = _selected.includes(genericItem.id);
                return <ui.LinkBox key={genericItem.id ?? i} ml={spacing} mt={spacing} width={width}>
                    <RouterLinkOverlay
                        to={linkTo?.(item) ?? '/'}
                        onClick={(e) => {
                            if (onSelect) {
                                onSelect(item);
                                e.preventDefault();
                            }
                        }}
                    >
                        {Component && <Component
                            item={item}
                            isSelected={isSelected}
                        />}
                        {!Component && <ui.Box
                            border="1px"
                            borderColor={isSelected ? 'gray.600' : 'gray.300'}
                            bg={isSelected ? 'gray.600' : undefined}
                            borderRadius={radius}
                            py={0.5} pl={0.5} pr={1.5}
                        >
                            <ui.HStack spacing={1}>
                                <S3Image
                                    base={genericItem.icon.base_url}
                                    processor="cov128"
                                    borderRadius={radius}
                                    border="1px solid #fff"
                                    width={iconSize}
                                    height={iconSize}
                                />
                                <ui.Text
                                    fontWeight="bold"
                                    fontSize={size}
                                    color={isSelected ? 'white' : undefined}
                                >
                                    {genericItem.name}
                                </ui.Text>
                            </ui.HStack>
                        </ui.Box>}
                    </RouterLinkOverlay>
                </ui.LinkBox>;
            })}
        </ui.Flex>
    </ui.Box>;
};

