import {React, ui, icons, Router, urls, gql, graphql} from 'lib';

import {Workspace, WorkspaceMember} from 'types/stock/api/data.gen';
import {Section, LoadingScreen, Loading, SimpleCenteredContent, useRemoteData, LayoutItem, S3Image, S3ImageWithPreview, RouterTextLink, useSession, useSessionState, useWriterApi, useForm, input, ImageInput, imgurl, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, AlertDialog, InputProps} from 'shared';
import {ProfileIdInput} from 'components/profile/input';
import {ItemCreationModal} from 'components/item/creation';
import {ItemList} from 'components/item/list';
import {StorageCreationModal} from 'components/storage/creation';
import {GenericList} from 'components/generic/list';
import {StorageList} from 'components/storage/list';
import {WorkspaceProvider} from 'components/workspace/context';
import {withFrame} from 'components/frame';


export const WorkspaceDetail = withFrame(({workspaceId}: {
    workspaceId: string;
}): React.ReactElement => {
    const history = Router.useHistory();
    const moveToHome = () => history.replace(urls.top());
    const api = useRemoteData({
        path: 'get_workspace',
        request: {workspace_id: workspaceId},
        reloadWhenFailed: true,
        onClientError: moveToHome,
    });
    const editionDisclosure = ui.useDisclosure();

    const itemCreationDisclosure = ui.useDisclosure();
    const itemListRef = React.useRef<Ref<typeof ItemList>>(null);

    const storageCreationDisclosure = ui.useDisclosure();
    const storageListRef = React.useRef<Ref<typeof StorageList>>(null);

    if (api.state !== 'success') {
        return <LoadingScreen />
    }
    const {workspace, memberships} = api.data;

    return <WorkspaceProvider workspace={workspace}>
        <Section>
            <LayoutItem>
                <ui.HStack>
                    <ui.Text>
                        <RouterTextLink to={urls.home()}>HOME</RouterTextLink>
                    </ui.Text>
                </ui.HStack>
            </LayoutItem>

            <LayoutItem.Group space={4}>
                <LayoutItem>
                    <ui.Box>
                        <ui.HStack width="100%">
                            <S3ImageWithPreview
                                base={workspace.icon.base_url}
                                processor="cov128"
                                borderRadius="4px"
                                width="64px"
                                height="64px"
                                mr={2}
                                previewTitle={workspace.name}
                            />

                            <ui.Heading size="xl">
                                {workspace.name}
                            </ui.Heading>

                            <ui.Spacer />

                            <ui.IconButton
                                onClick={editionDisclosure.onOpen}
                                icon={<icons.EditIcon />}
                                aria-label="edit-workspace"
                            />
                        </ui.HStack>
                    </ui.Box>
                </LayoutItem>

                {!!workspace.note && <LayoutItem>
                    <ui.Text whiteSpace="pre-line" fontSize="sm">
                        {workspace.note}
                    </ui.Text>
                </LayoutItem>}

                <LayoutItem>
                    <ui.HStack>
                        <ui.Button
                            colorScheme="blue"
                            onClick={itemCreationDisclosure.onOpen}
                            leftIcon={<icons.AddIcon />}
                        >
                            アイテムを登録
                        </ui.Button>

                        <ui.Button
                            onClick={storageCreationDisclosure.onOpen}
                            leftIcon={<icons.AddIcon />}
                        >
                            保管場所を登録
                        </ui.Button>
                    </ui.HStack>
                </LayoutItem>
            </LayoutItem.Group>
        </Section>

        <Section title="保管場所">
            <StorageList
                ref={storageListRef}
                workspaceIds={[workspace.id]}
                linkTo={(storage) => urls.storage(storage.id)}
                empty={`${workspace.name}には保管場所が登録されていません`}
            />
        </Section>

        <Section
            title="アイテム"
            titleRightAccessory={<ui.Button
                size="sm"
                colorScheme="blue"
                onClick={itemCreationDisclosure.onOpen}
                leftIcon={<icons.AddIcon />}
            >
                登録
            </ui.Button>}
        >
            <ItemList
                ref={itemListRef}
                workspace={workspace}
                linkTo={(item) => urls.item(item.id)}
                empty={`${workspace.name}にはアイテムが登録されていません`}
                onCreated={(item) => {
                    history.push(urls.item(item.id));
                }}
            />
        </Section>

        <RecentStockSection workspace={workspace} />

        <MemberSection workspace={workspace} memberships={memberships} />

        <ItemCreationModal
            {...itemCreationDisclosure}
            workspace={workspace}
            onComplete={(item) => {
                history.push(urls.item(item.id));
                itemCreationDisclosure.onClose();
            }}
            onCompleteAndThen={() => {
                itemListRef.current?.reload();
            }}
        />

        <StorageCreationModal
            {...storageCreationDisclosure}
            workspace={workspace}
            onComplete={(storage) => {
                history.push(urls.storage(storage.id));
                storageCreationDisclosure.onClose();
            }}
            onCompleteAndThen={() => {
                storageListRef.current?.reload();
            }}
        />

        <WorkspaceEditionModal
            {...editionDisclosure}
            workspace={workspace}
            onComplete={(workspace) => {
                api.setData({workspace, memberships});
                editionDisclosure.onClose();
            }}
            canDelete={memberships.some(m => m.role === 'owner')}
            onDeleted={() => history.replace(urls.home())}
        />
    </WorkspaceProvider>
});





const WorkspaceEditionModal = ({
    workspace,
    onComplete,
    canDelete,
    onDeleted,
    ...props
}: {
    workspace: Workspace;
    onComplete(workspace: Workspace): void;
    canDelete: boolean;
    onDeleted(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_workspace';
    const session = useSession();
    const api = useWriterApi(path);
    const deleteApi = useWriterApi('delete_workspace');

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        workspace_id: workspace.id,
        icon: imgurl(workspace.icon.base_url, 'cov128'),
        name: workspace.name,
        note: workspace.note,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    const confirmDisclosure = ui.useDisclosure();
    const [confirmationText, setConfirmationText] = React.useState('');
    React.useEffect(() => {
        setConfirmationText('');
    }, [confirmDisclosure.isOpen]);

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ワークスペースの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名前"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：仕事、プライベート、家族"
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="4px"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>

                    <FormItem
                        label="メモ"
                        keyPath="note"
                        error={api.error?.data}
                    >
                        <input.Textarea
                            {...binder.mapInputProps('note')}
                            placeholder=""
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button
                    colorScheme="red"
                    onClick={confirmDisclosure.onOpen}
                    isDisabled={!canDelete}
                >
                    削除
                </ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={async (data) => {
                await session.reload();
                onComplete(data);
                reset();
                api.reset();
            }}
        />

        <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={() => deleteApi.call({workspace_id: workspace.id}).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
            isDisabled={confirmationText !== workspace.name}
        >
            <LayoutItem>
                <ui.Text color="red">
                    ワークスペースを削除すると、<ui.chakra.span fontWeight="bold">{workspace.name}</ui.chakra.span>に含まれるすべての保管場所、アイテム、在庫も削除されます。
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    この操作は取り消せません。<br />
                    <br />
                    削除するには <ui.chakra.span fontWeight="bold">{workspace.name}</ui.chakra.span> を入力して「削除する」ボタンを押してください。
                </ui.Text>

                <ui.Input
                    mt={2}
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                    placeholder="ワークスペース名を入力"
                />
            </LayoutItem>
        </AlertDialog>
        <ApiSpinnerDialog api={deleteApi} />
        <ApiCompletionDialog
            message="データを削除しました"
            api={deleteApi}
            onOk={async () => {
                await session.reload();
                onDeleted();
            }}
        />
    </ui.Modal>;
};


gql`
query GetRecentUpdatedStocks($id: PK!, $after: String) {
    recentUpdatedStocks(workspaceIds: [$id], first: 15, after: $after) {
        edges {
            node {
                id
                pk
                quantity
                item {
                    icon {
                        baseUrl
                    }
                    name
                }
                storage {
                    name
                }
            }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
    }
}
`;

type StockEdge = ArrayItem<NonNullable<NonNullable<graphql.GetRecentUpdatedStocksQuery>['recentUpdatedStocks']>['edges']>;

const RecentStockSection = ({workspace}: {
    workspace: Workspace;
}) => {
    const [after, setAfter] = React.useState<string | null>();
    const [items, setItems] = React.useState([] as StockEdge[]);
    const api = graphql.useGetRecentUpdatedStocksQuery({
        variables: {id: workspace.id, after},
        fetchPolicy: 'no-cache',
    });
    const data = api.data?.recentUpdatedStocks;

    React.useEffect(() => {
        if (data?.edges) {
            setItems(items => items.concat(data.edges));
        }
    }, [data]);

    if (items.length === 0) {
        return null;
    }

    return <Section title="最近更新した在庫">
        <LayoutItem>
            <GenericList
                items={items}
                as={RecentStockComponent}
                linkTo={(edge) => urls.stock(edge?.node?.pk ?? '')}
            />
        </LayoutItem>
        {data?.pageInfo.hasNextPage && <LayoutItem>
            <ui.Text>
                <ui.Link
                    color="blue.500"
                    onClick={() => setAfter(data?.pageInfo?.endCursor)}
                >
                    続きを読み込む
                </ui.Link>
            </ui.Text>
        </LayoutItem>}
    </Section>
};


const RecentStockComponent = ({item: edge, isSelected}: {
    item: StockEdge;
    isSelected: boolean;
}) => {
    return <ui.Box
        border="1px"
        borderColor={isSelected ? 'gray.600' : 'gray.300'}
        bg={isSelected ? 'gray.600' : undefined}
        borderRadius={4}
        py={0.5} pl={0.5} pr={1.5}
    >
        <ui.HStack spacing={1}>
            <ui.Box
                position="relative"
                borderRadius={4}
                overflow="hidden"
            >
                <S3Image
                    base={edge?.node?.item.icon.baseUrl}
                    processor="cov128"
                    width="44px"
                    height="44px"
                />

                <ui.Text
                    bg="rgba(0, 0, 0, 0.3)"
                    color="white"
                    display="inline-block"
                    position="absolute"
                    height="20px"
                    left={0}
                    bottom={0}
                    px={1}
                    fontSize="xs"
                >
                    {edge?.node?.quantity}
                </ui.Text>
            </ui.Box>

            <ui.Box>
                <ui.Text
                    fontWeight="bold"
                    fontSize="sm"
                    color={isSelected ? 'white' : undefined}
                >
                    {edge?.node?.item.name}
                </ui.Text>

                <ui.Text
                    fontSize="xs"
                    color={isSelected ? 'white' : undefined}
                >
                    {edge?.node?.storage.name}
                </ui.Text>
            </ui.Box>
        </ui.HStack>
    </ui.Box>;
};


const MemberSection = ({workspace, memberships}: {
    workspace: Workspace;
    memberships: WorkspaceMember[];
}) => {
    const listRef = React.useRef<Ref<typeof MemberList>>(null);
    const addDisclosure = ui.useDisclosure();
    const invitationDisclosure = ui.useDisclosure();
    const [member, setMember] = React.useState<WorkspaceMember>();
    const memberDisclosure = ui.useDisclosure({
        isOpen: !!member,
        onClose: () => setMember(undefined),
    });

    const canAdd = memberships.some(m => m.role !== 'member');
    const session = useSession();

    return <Section
        title="メンバー"
        titleRightAccessory={canAdd && <>
            {session.data.teams.length > 0 && <ui.Button
                size="sm"
                onClick={addDisclosure.onOpen}
                leftIcon={<icons.AddIcon />}
            >
                チーム
            </ui.Button>}
            <ui.Button
                size="sm"
                colorScheme="blue"
                onClick={invitationDisclosure.onOpen}
                leftIcon={<icons.AddIcon />}
            >
                招待
            </ui.Button>
        </>}
    >
        <MemberList
            ref={listRef}
            workspace={workspace}
            onClick={setMember}
        />

        <WorkspaceMemberAdditionModal
            {...addDisclosure}
            memberships={memberships}
            workspace={workspace}
            onComplete={() => {
                addDisclosure.onClose();
                listRef.current?.reload();
            }}
        />

        <WorkspaceMemberInvitationModal
            {...invitationDisclosure}
            memberships={memberships}
            workspace={workspace}
            onComplete={() => {
                invitationDisclosure.onClose();
            }}
        />

        <WorkspaceMemberModal 
            {...memberDisclosure}
            workspace={workspace}
            memberships={memberships}
            member={member}
            onComplete={() => {
                listRef.current?.reload();
                setMember(undefined);
            }}
            onRemoved={() => {
                listRef.current?.reload();
                setMember(undefined);
            }}
        />
    </Section>
}

const MemberList = React.forwardRef<{
    reload(): void;
}, {
    workspace: Workspace;
    onClick(member: WorkspaceMember): void;
}>(({workspace, onClick}, ref) => {
    const api = useRemoteData({
        path: 'get_workspace_members',
        request: {workspace_id: workspace.id},
        reloadWhenFailed: true,
    });
    React.useImperativeHandle(ref, () => ({
        reload: () => api.reload(),
    }));
    if (api.state !== 'success') {
        return <Loading />;
    }
    return <ui.Box>
        <ui.AvatarGroup>
            {api.data.items.map((m) => <ui.Avatar
                key={m.id}
                src={imgurl(m.rel.profile.icon.base_url, 'cov128')}
                onClick={() => onClick(m)}
                cursor="pointer"
            />)}
        </ui.AvatarGroup>
    </ui.Box>;
});


const WorkspaceMemberAdditionModal = ({
    memberships,
    workspace,
    onComplete,
    ...props
}: {
    memberships: WorkspaceMember[];
    workspace: Workspace;
    onComplete(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'add_workspace_member';
    const api = useWriterApi(path);

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>, 'profile_id'>({
        workspace_id: workspace.id,
        profile_id: undefined,
        role: 'member',
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">チームの追加</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="チーム"
                        keyPath="profile_id"
                        error={api.error?.data}
                    >
                        <ProfileIdInput
                            excludeUser={true}
                            {...binder.mapInputProps('profile_id')}
                        />
                    </FormItem>

                    <FormItem
                        label="権限"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        <RoleInput
                            owner={memberships.some(m => m.role === 'owner')}
                            {...binder.mapInputProps('role')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    追加
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={async () => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const WorkspaceMemberInvitationModal = ({
    memberships,
    workspace,
    onComplete,
    ...props
}: {
    memberships: WorkspaceMember[];
    workspace: Workspace;
    onComplete(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'send_workspace_invitation';
    const api = useWriterApi(path);

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        workspace_id: workspace.id,
        email: '',
        role: 'member',
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">メンバーの招待</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="メールアドレス"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <input.Input
                            type="email"
                            placeholder="email"
                            {...binder.mapInputProps('email')}
                        />
                    </FormItem>

                    <FormItem
                        label="権限"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        <RoleInput
                            owner={memberships.some(m => m.role === 'owner')}
                            {...binder.mapInputProps('role')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    招待を送る
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={async () => {
                onComplete();
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};


const WorkspaceMemberModal = ({
    workspace,
    memberships,
    member,
    onComplete,
    onRemoved,
    ...props
}: {
    workspace: Workspace;
    memberships: WorkspaceMember[],
    member: WorkspaceMember | undefined;
    onComplete(member: WorkspaceMember): void;
    onRemoved(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_workspace_member';
    const api = useWriterApi(path);
    const removeApi = useWriterApi('remove_workspace_member');

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>, keyof Req<typeof path>>({
        workspace_id: member?.workspace_id,
        member_id: member?.id,
        role: member?.role,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    const roles = memberships.filter(m => m.id !== member?.id).map(m => m.role);
    const editable = roles.includes('owner') || (roles.includes('admin') && member?.role !== 'owner');

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">{member?.rel.type === 'team' ? 'チーム' : 'メンバー'}情報</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>

                    <LayoutItem>
                        <ui.HStack width="100%">
                            <S3Image
                                base={member?.rel.profile.icon.base_url}
                                processor="cov128"
                                borderRadius="full"
                                width="44px"
                                height="44px"
                                mr={2}
                            />

                            <ui.Text>
                                {member?.rel.type === 'team' && <RouterTextLink to={urls.team(member.rel.id)}>{member.rel.profile.name}</RouterTextLink>}
                                {member?.rel.type !== 'team' && member?.rel.profile.name}
                            </ui.Text>
                        </ui.HStack>
                    </LayoutItem>

                    <FormItem
                        label="権限"
                        keyPath="role"
                        error={api.error?.data}
                    >
                        <RoleInput
                            owner={roles.includes('owner')}
                            isDisabled={!editable}
                            {...binder.mapInputProps('role')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button
                    colorScheme="red"
                    onClick={confirmDisclosure.onOpen}
                    isDisabled={!editable}
                >
                    除外
                </ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                    isDisabled={!editable}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={async (data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />

        {member && <AlertDialog
            {...confirmDisclosure}
            title="除外の確認"
            confirm="除外する"
            onConfirm={() => removeApi.call({
                workspace_id: workspace.id,
                member_id: member.id,
            }).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text color="red">
                    {member?.rel.profile.name}を{workspace.name}のメンバーから外します
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    本当に除外してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>}
        <ApiSpinnerDialog api={removeApi} />
        <ApiCompletionDialog
            message="メンバーを解除しました"
            api={removeApi}
            onOk={() => {
                removeApi.reset();
                onRemoved();
            }}
        />
    </ui.Modal>;
};


export const WorkspaceInvitation = withFrame(({token}: {
    token: string;
}) => {
    const history = Router.useHistory();
    const session = useSessionState();
    const {loading, data: workspace} = useRemoteData({
        path: 'retrieve_workspace_from_invitation',
        request: {token},
    });

    const path = 'accept_workspace_invitation';
    const api = useWriterApi(path);
    const submit = async () => {
        const data = await api.call({token});
        if (data && session.ready) {
            session.reload();
            history.push(urls.workspace(data.id));
        }
    };

    React.useEffect(() => {
        if (workspace && session.ready && session.data.workspaces.find(w => w.id === workspace.id)) {
            history.replace(urls.workspace(workspace.id));
        }
    }, [history, session, workspace]);

    if (loading) {
        return <LoadingScreen />
    }

    if (!workspace) {
        return <SimpleCenteredContent>
            <ui.VStack spacing={5}>
                <ui.Text color="red.500">
                    URLが間違っているか有効期限切れです
                </ui.Text>

                <ui.Button onClick={() => history.replace('/')}>
                    トップページに戻る
                </ui.Button>
            </ui.VStack>
        </SimpleCenteredContent>;
    }

    return <SimpleCenteredContent title="ワークスペースに招待されています">
        <ui.VStack spacing={5}>
            <ui.HStack width="100%">
                <S3Image
                    base={workspace.icon.base_url}
                    processor="cov128"
                    borderRadius="4px"
                    width="64px"
                    height="64px"
                    mr={2}
                />

                <ui.Text>
                    ワークスペース「{workspace.name}」に招待されています。
                </ui.Text>
            </ui.HStack>

            <ui.Button onClick={submit} width="100%" isDisabled={api.loading || session.loading}>
                承諾
            </ui.Button>
        </ui.VStack>
    </SimpleCenteredContent>;
});



type Role = WorkspaceMember['role'];

const RoleInput = ({value, onChange, owner, isDisabled = false}: {
    owner: boolean;
    isDisabled?: boolean;
} & InputProps<Role | undefined, Role>) => {
    const choices = React.useMemo(() => [
        {value: 'owner', label: 'オーナー'},
        {value: 'admin', label: '管理者'},
        {value: 'member', label: 'メンバー'},
    ], []);
    return <ui.RadioGroup onChange={(v) => onChange(null, v as Role)} value={value}>
        <ui.Stack direction="row">
            {choices.map(({value, label}) => <ui.Radio
                value={value}
                isDisabled={isDisabled || (value === 'owner' && !owner)}
                key={value}
            >
                {label}
            </ui.Radio>)}
        </ui.Stack>
    </ui.RadioGroup>
} 
