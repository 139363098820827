import {React} from 'lib';


export const isArray = (obj: unknown): obj is unknown[] => Object.prototype.toString.call(obj) === '[object Array]';
export const isObject = (obj: unknown): obj is {[k: string]: unknown} => Object.prototype.toString.call(obj) === '[object Object]';
export const isFunction = (obj: unknown): obj is Function => typeof obj === 'function' || Object.prototype.toString.call(obj) === '[object Function]';


export const equals = (a: unknown, b: unknown): boolean => {
    if (isArray(a)) {
        return isArray(b) && a.length === b.length && a.every((v, i) => (equals(v, b[i])));
    } else if (isObject(a)) {
        if (!isObject(b)) { return false; }
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);
        return keysA.length === keysB.length && keysA.every((k) => (equals(a[k], b[k])));
    } else {
        return a === b;
    }
};


export const useChangeCount = <T extends any>(obj: T, compare?: {(a: T, b: T): boolean}): number => {
    const previous = React.useRef(obj);
    const counter = React.useRef(0);

    const prev = previous.current;
    if (obj !== prev && !(compare ?? equals)(obj, prev)) {
        counter.current += 1;
        previous.current = obj;
    }

    return counter.current;
};


export function formatComma(num: number | null | undefined): string {
    if (num == null) { return ''; }
    if (num === 0) { return '0'; }
    var parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export const formatPrice = (num: number): string => `¥${formatComma(num)}`;
