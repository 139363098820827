import {React} from 'lib';

import {Loading, useRemoteData} from 'shared';
import {GenericList, GenericListPropsButItems} from 'components/generic/list';
import {Storage} from 'types/stock/api/data.gen';



export const StorageList = React.forwardRef<{
    reload(): void;
}, {
    workspaceIds?: string[];
} & GenericListPropsButItems<Storage>>(({workspaceIds, ...other}, ref): React.ReactElement => {
    const api = useRemoteData({
        path: 'list_storages',
        request: {
            workspace_ids: workspaceIds,
        },
        reloadWhenFailed: true,
    });

    React.useImperativeHandle(ref, () => ({
        reload: api.reload,
    }));

    if (api.state !== 'success') {
        return <Loading />
    }

    return <GenericList
        {...other}
        items={api.data.items}
    />
});
