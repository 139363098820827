import {React, ui} from 'lib';

import {Stock, Storage, Item} from 'types/stock/api/data.gen';
import {useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, useForm, input} from 'shared';

import {StorageIdInput} from 'components/storage/input';
import {ItemIdInput} from 'components/item/input';


export const StockSettingModal = ({
    storage,
    item,
    onComplete,
    onCompleteAndThen,
    ...props
}: {
    storage?: Storage;
    item?: Item;
    onComplete(stock: Stock): void;
    onCompleteAndThen?(stock: Stock): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'upsert_stock';
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>, 'storage_id' | 'item_id'>({
        storage_id: storage?.id,
        item_id: item?.id,
        quantity: 1,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">在庫の設定</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="保管場所"
                        keyPath="storage_id"
                        error={api.error?.data}
                    >
                        <StorageIdInput
                            {...binder.mapInputProps('storage_id')}
                        />
                    </FormItem>

                    <FormItem
                        label="アイテム"
                        keyPath="item_id"
                        error={api.error?.data}
                    >
                        <ItemIdInput
                            {...binder.mapInputProps('item_id')}
                        />
                    </FormItem>

                    <FormItem
                        label="数量"
                        keyPath="quantity"
                        error={api.error?.data}
                    >
                        <input.NumberInput min={0} {...binder.mapInputProps('quantity')}>
                            <ui.NumberInputField />
                            <ui.NumberInputStepper>
                                <ui.NumberIncrementStepper />
                                <ui.NumberDecrementStepper />
                            </ui.NumberInputStepper>
                        </input.NumberInput>
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    設定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
            okAndThen="続けて設定する"
            onOkAndThen={onCompleteAndThen ? (data) => {
                onCompleteAndThen?.(data);
                reset();
                api.reset();
            } : undefined}
        />
    </ui.Modal>;
};
