export const top = (): string => '/';
export const home = (): string => '/';
export const account = (): string => '/account';
export const commitEmailChange = (token: string): string => `/account/email_change/${token}`;
export const passwordReset = (token: string): string => `/account/password_reset/${token}`;
export const termsOfService = (): string => '/terms_of_service';
export const privacyPolicy = (): string => '/privacy_policy';
export const signup = (token?: string): string => `/signup/${token ?? ''}`;
export const team = (teamId?: number | string): string => `/team/${teamId}`;
export const teamInvitation = (token: string): string => `/team/invitation/${token}`;
export const workspace = (workspaceId?: number | string): string => `/workspace/${workspaceId}`;
export const workspaceInvitation = (token: string): string => `/workspace/invitation/${token}`;
export const storage = (storageId?: number | string): string => `/storage/${storageId}`;
export const item = (itemId?: number | string): string => `/item/${itemId}`;
export const stock = (stockId?: number | string): string => `/stock/${stockId}`;
