import {React, ui} from 'lib';


export const SimpleCenteredContent = ({children, title}: {
    title?: string;
    children: React.ReactNode;
}): React.ReactElement => {
    return <ui.Container centerContent>
        <ui.Flex height="100%" alignItems="center" direction="row">
            <ui.VStack width="300px">
                {title && <ui.Box mb={5}>
                    <ui.Text fontSize="xl" fontWeight="bold">{title}</ui.Text>
                </ui.Box>}

                <ui.Box width="100%">
                    {children}
                </ui.Box>
            </ui.VStack>
        </ui.Flex>
    </ui.Container>
};
