import {React, ui, icons, urls, Router} from 'lib';

import {LayoutItem, useSession, Section, RouterTextLink, S3ImageWithPreview, useRemoteData, useWriterApi, useForm, Loading, Req, FormItem, input, ApiSpinnerDialog, ApiCompletionDialog, LoadingScreen, SimpleCenteredContent, AlertDialog} from 'shared';
import {withFrame, withLoginExemptFrame} from 'components/frame';
import {useUser} from 'components/account';
import {ProfileEditionModal} from 'components/profile/edition';


export const Account = withFrame((): React.ReactElement => {
    const user = useUser();
    const session = useSession();
    const {loading, data} = useRemoteData({
        path: 'get_account',
        request: {},
        reloadWhenFailed: true,
    });
    const editionDisclosure = ui.useDisclosure();
    const emailDisclosure = ui.useDisclosure();
    const passwordDisclosure = ui.useDisclosure();
    return <>
        <Section>
            <LayoutItem>
                <ui.HStack>
                    <ui.Text>
                        <RouterTextLink to={urls.home()}>HOME</RouterTextLink>
                    </ui.Text>
                </ui.HStack>
            </LayoutItem>

            <LayoutItem.Group space={4}>
                <LayoutItem>
                    <ui.Box>
                        <ui.HStack width="100%">
                            <S3ImageWithPreview
                                base={user.profile.icon.base_url}
                                processor="cov128"
                                borderRadius="full"
                                width="64px"
                                height="64px"
                                mr={2}
                                previewTitle={user.profile.name}
                            />

                            <ui.Heading size="xl">
                                {user.profile.name}
                            </ui.Heading>

                            <ui.Spacer />

                            <ui.IconButton
                                onClick={editionDisclosure.onOpen}
                                icon={<icons.EditIcon />}
                                aria-label="edit-user"
                            />
                        </ui.HStack>
                    </ui.Box>
                </LayoutItem>
            </LayoutItem.Group>
        </Section>

        <Section title="メールアドレス">
            {loading && <Loading />}
            {data && <ui.HStack>
                <ui.Text>{data.account.email}</ui.Text>
                <ui.Spacer />
                <ui.Button onClick={emailDisclosure.onOpen}>変更</ui.Button>
            </ui.HStack>}
        </Section>

        <Section title="パスワード">
            <ui.HStack>
                <ui.Text>********</ui.Text>
                <ui.Spacer />
                <ui.Button onClick={passwordDisclosure.onOpen}>変更</ui.Button>
            </ui.HStack>
        </Section>

        {data && <UnregistrationSection email={data.account.email} />}

        <ProfileEditionModal
            {...editionDisclosure}
            profile={user.profile}
            onComplete={() => {
                session.reload();
                editionDisclosure.onClose();
            }}
        />

        <EmailChangeModal
            {...emailDisclosure}
            email={data?.account.email ?? ''}
            onComplete={() => {
                emailDisclosure.onClose();
            }}
        />

        <PasswordChangeModal
            {...passwordDisclosure}
            onComplete={() => {
                passwordDisclosure.onClose();
            }}
        />
    </>
});


const UnregistrationSection = ({email}: {
    email: string;
}) => {
    const api = useWriterApi('unregister_user');
    const session = useSession();
    const confirmDisclosure = ui.useDisclosure();
    const [confirmationText, setConfirmationText] = React.useState('');
    React.useEffect(() => {
        setConfirmationText('');
    }, [confirmDisclosure.isOpen]);

    return <Section title="退会">
        <ui.Button
            colorScheme="red"
            onClick={confirmDisclosure.onOpen}
        >
            退会する
        </ui.Button>

        <AlertDialog
            {...confirmDisclosure}
            title="退会の確認"
            confirm="退会する"
            onConfirm={() => api.call({}).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
            isDisabled={confirmationText !== email}
        >
            <LayoutItem>
                <ui.Text color="red">
                    退会してアクセスできなくなったデータの復元はできません。
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    退会するにはこのアカウントのメールアドレスを入力して「退会する」ボタンを押してください。
                </ui.Text>

                <ui.Input
                    mt={2}
                    value={confirmationText}
                    onChange={(e) => setConfirmationText(e.target.value)}
                    placeholder="メールアドレスを入力"
                />
            </LayoutItem>
        </AlertDialog>
        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            message="退会処理が完了しました"
            api={api}
            onOk={async () => {
                await session.reload();
            }}
        />
    </Section>;
};


export const EmailChangeModal = ({
    email,
    onComplete,
    ...props
}: {
    email: string;
    onComplete(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'send_email_to_change_address';
    const api = useWriterApi(path);

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        email: '',
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">メールアドレスの変更</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem label="現在のメールアドレス">
                        <ui.Input value={email} isReadOnly={true} />
                    </FormItem>
                    <FormItem
                        label="変更後のメールアドレス"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('email')}
                            type="email"
                            placeholder="email"
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    確認メールを送る
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
            title="確認メールを送信しました"
            message="入力したメールアドレスの受信ボックスをご確認ください"
        />
    </ui.Modal>;
};


export const PasswordChangeModal = ({
    onComplete,
    ...props
}: {
    onComplete(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'change_password';
    const api = useWriterApi(path);

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        password: '',
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">パスワードの変更</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="変更後のパスワード"
                        keyPath="password"
                        error={api.error?.data}
                    >
                        <input.PasswordInput
                            {...binder.mapInputProps('password')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
            title="パスワードを変更しました"
        />
    </ui.Modal>;
};


export const CommitEmailChange = withFrame(({token}: {
    token: string;
}): React.ReactElement => {
    const history = Router.useHistory();
    const toast = ui.useToast()
    const api = useWriterApi('commit_email_change');
    const call = React.useRef(() => api.call({token}).then(() => {
        toast({
            title: 'メールアドレスを変更しました',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
        history.replace(urls.account());
    }));

    React.useEffect(() => {
        call.current()
    }, []);

    if (api.state === 'failure') {
        return <SimpleCenteredContent title="メールアドレスの変更">
            <ui.VStack spacing={5}>
                <ui.Text color="red.500">
                    URLが間違っているか有効期限切れです
                </ui.Text>

                <ui.Button onClick={() => history.replace('/')}>
                    トップページに戻る
                </ui.Button>
            </ui.VStack>
        </SimpleCenteredContent>;
    }

    return <LoadingScreen />
});


export const PasswordReset = withLoginExemptFrame(({token}: {
    token: string;
}): React.ReactElement => {
    const {loading, data: email} = useRemoteData({
        path: 'retrieve_email_from_token',
        request: {token, check_used: false},
    });

    const history = Router.useHistory();
    const toast = ui.useToast()
    const path = 'reset_password';
    const api = useWriterApi(path);

    const {binder, handleSubmit} = useForm<Req<typeof path>>({
        token,
        password: '',
    });

    const submit = handleSubmit(data => api.call(data).then(() => {
        toast({
            title: 'パスワードを変更しました',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
        history.replace(urls.account());
    }));

    if (loading) {
        return <LoadingScreen />
    }

    if (!email) {
        return <SimpleCenteredContent title="パスワードリセット">
            <ui.VStack spacing={5}>
                <ui.Text color="red.500">
                    URLが間違っているか有効期限切れです
                </ui.Text>

                <ui.Button onClick={() => history.replace('/')}>
                    トップページに戻る
                </ui.Button>
            </ui.VStack>
        </SimpleCenteredContent>;
    }

    return <SimpleCenteredContent title="パスワードリセット">
        <form onSubmit={submit}>
            <FormItem label="メールアドレス">
                <ui.Input
                    value={email}
                />
            </FormItem>

            <FormItem
                label="変更後のパスワード"
                keyPath="password"
                error={api.error?.data}
            >
                <input.PasswordInput
                    {...binder.mapInputProps('password')}
                />
            </FormItem>

            {api.error && <LayoutItem>
                <ui.Text color="red.500">
                    {api.error?.message}
                </ui.Text>
            </LayoutItem>}

            <LayoutItem>
                <ui.Button onClick={submit} width="100%" isDisabled={api.loading}>
                    送信
                </ui.Button>
            </LayoutItem>
        </form>
    </SimpleCenteredContent>
});
