import {React, ui, Router, urls} from 'lib';

import {LoadingScreen, useRemoteData, useWriterApi, Req, Res, FormItem, ApiSpinnerDialog, ApiCompletionDialog, AlertDialog, LayoutItem, S3Image, RouterTextLink, useForm, input} from 'shared';
import {StockLogs} from 'components/stock/logs';
import {withFrame} from 'components/frame';
import {WorkspaceProvider} from 'components/workspace/context';
import {Stock, Item} from 'types/stock/api/data.gen';


export const StockDetail = withFrame(({stockId}: {
    stockId: string;
}): React.ReactElement => {
    const history = Router.useHistory();
    const api = useRemoteData({
        path: 'get_stock',
        request: {stock_id: stockId},
        reloadWhenFailed: true,
        onClientError: () => history.replace('/'),
    });
    const stockLogsRef = React.useRef<Ref<typeof StockLogs>>(null);

    if (api.state !== 'success') {
        return <LoadingScreen />
    }

    const {workspace, storage, item, stock} = api.data;

    return <WorkspaceProvider workspace={workspace}>
        <LayoutItem>
            <ui.HStack>
                <ui.Text>
                    <RouterTextLink to={urls.home()}>HOME</RouterTextLink>
                </ui.Text>

                <ui.Text>
                    {'>'} <RouterTextLink to={urls.workspace(workspace.id)}>{workspace.name}</RouterTextLink>
                </ui.Text>
            </ui.HStack>
        </LayoutItem>

        <LayoutItem>
            <ui.Heading size="xl">
                在庫の設定
            </ui.Heading>
        </LayoutItem>

        <LayoutItem>
            <ui.Box display={{sm: 'flex'}}>
                <ui.Box flexBasis="50%">
                    <FormItem label="保管場所">
                        <ui.HStack>
                            <S3Image
                                base={storage.icon.base_url}
                                processor="cov128"
                                borderRadius="full"
                                width="32px"
                                height="32px"
                                mr={2}
                            />

                            <ui.Text>
                                <RouterTextLink to={urls.storage(storage.id)}>
                                    {storage.name}
                                </RouterTextLink>
                            </ui.Text>
                        </ui.HStack>
                    </FormItem>
                </ui.Box>

                <ui.Box flexBasis="50%" mt={{base: 6, sm: 0}}>
                    <FormItem label="アイテム">
                        <ui.HStack>
                            <S3Image
                                base={item.icon.base_url}
                                processor="cov128"
                                borderRadius="4px"
                                width="32px"
                                height="32px"
                                mr={2}
                            />

                            <ui.Text>
                                <RouterTextLink to={urls.item(item.id)}>
                                    {item.name}
                                </RouterTextLink>
                            </ui.Text>
                        </ui.HStack>
                    </FormItem>
                </ui.Box>
            </ui.Box>
        </LayoutItem>

        <LayoutItem>
            <StockEditionForm
                stock={stock}
                item={item}
                onComplete={({stock, log}) => {
                    api.setData({...api.data, stock});
                    if (log) {
                        stockLogsRef.current?.prepend(log);
                    }
                }}
            />
        </LayoutItem>

        <LayoutItem>
            <ui.Heading size="xl">
                更新履歴
            </ui.Heading>
        </LayoutItem>

        <LayoutItem>
            <StockLogs
                ref={stockLogsRef}
                stock={stock}
                item={item}
                empty={<ui.Text color="gray.500">
                    まだ在庫の更新がありません
                </ui.Text>}
            />
        </LayoutItem>

        <LayoutItem>
            <ui.Heading size="xl">
                操作
            </ui.Heading>
        </LayoutItem>

        <LayoutItem>
            <DeleteButton
                stock={stock}
                onDeleted={() => history.replace(urls.workspace(workspace.id))}
            />
        </LayoutItem>
    </WorkspaceProvider>;
});



const StockEditionForm = ({
    stock,
    item,
    onComplete,
}: {
    stock: Stock;
    item: Item;
    onComplete(stock: Res<'update_stock'>): void;
}): React.ReactElement => {
    const path = 'update_stock';
    const api = useWriterApi(path);

    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        stock_id: stock.id,
        quantity: stock.quantity,
        comment: '',
    });

    React.useEffect(() => {
        reset();
    }, [reset, stock]);

    const submit = handleSubmit(data => api.call(data));

    return <form onSubmit={submit}>
        <FormItem
            label="数量"
            keyPath="quantity"
            error={api.error?.data}
        >
            <ui.HStack>
                <input.NumberInput min={0} {...binder.mapInputProps('quantity')}>
                    <ui.NumberInputField />
                    <ui.NumberInputStepper>
                        <ui.NumberIncrementStepper />
                        <ui.NumberDecrementStepper />
                    </ui.NumberInputStepper>
                </input.NumberInput>

                {item.unit ? <ui.Text>
                    {item.unit}
                </ui.Text> : null}
            </ui.HStack>
        </FormItem>

        <FormItem
            label="コメント"
            keyPath="comment"
            error={api.error?.data}
        >
            <input.Textarea
                {...binder.mapInputProps('comment')}
                placeholder=""
            />
        </FormItem>

        <LayoutItem>
            <ui.HStack>
                <ui.Spacer />
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                    isDisabled={binder.value.quantity === stock.quantity}
                >
                    更新
                </ui.Button>
            </ui.HStack>
        </LayoutItem>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                api.reset();
            }}
        />
    </form>;
};


const DeleteButton = ({
    stock,
    onDeleted,
}: {
    stock: Stock;
    onDeleted(): void;
}): React.ReactElement => {
    const deleteApi = useWriterApi('delete_stock');
    const confirmDisclosure = ui.useDisclosure();

    return <>
        <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>

        <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={() => deleteApi.call({stock_id: stock.id}).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text color="red">
                    この在庫データを削除すると履歴もすべて削除されます
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    この操作は取り消せません。<br />
                    本当に削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>
        <ApiSpinnerDialog api={deleteApi} />
        <ApiCompletionDialog
            message="データを削除しました"
            api={deleteApi}
            onOk={onDeleted}
        />
    </>;
};
