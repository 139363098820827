import {React} from 'lib';

import {isArray, equals} from 'utils';


export const useDataBinder = <T, >(data: T, onChange: {(val: T): void}): DataBinder<T> => {
    const binder = React.useRef(new RootDataBinder(data, onChange));
    binder.current.update(data, onChange);
    return binder.current;
};


export type InputProps<V, ChangeV extends V = V, E = unknown> = {
    value: V;
    onChange(e: E, value: ChangeV): void;
}


export interface DataBinder<Val, Parent = unknown> {
    readonly parent: DataBinder<Parent>;
    readonly value: Val;
    readonly keyPath: string;
    readonly isRoot: boolean;

    change(newValue: Val, sideEffect?: Partial<Parent>): void;
    assign(delta: Partial<Val>): Val;
    bind<K extends keyof Val>(name: K): DataBinder<Val[K], Val>;
    map<K extends keyof Val, Mapped>(name: K, map: {(binder: DataBinder<Val[K], Val>): Mapped}): Mapped;
    makeKeyPath(name: keyof Val): string;

    onChange<E = unknown>(_e: E, v: Val): void;
    inputProps<CV extends Val, E = unknown>(defaultValue?: CV): InputProps<Val, CV, E>;
    mapInputProps<K extends keyof Val, CV extends Val[K], E = unknown>(name: K, defaultValue?: CV): InputProps<Val[K], CV, E>;
}


abstract class BaseDataBinder<Val, Parent = unknown> implements DataBinder<Val, Parent> {
    abstract get parent(): DataBinder<Parent>;
    abstract get value(): Val;
    abstract get keyPath() : string;
    abstract get isRoot(): boolean;

    abstract change(newValue: Val, sideEffect?: Partial<Parent>): void;
    abstract assign(delta: Partial<Val>): Val;
    abstract makeKeyPath(name: keyof Val): string;

    _cache: {[K in keyof Val]?: ChildDataBinder<Val, K>};

    constructor() {
        this._cache = {};
        this.onChange = this.onChange.bind(this);
        this.bind = this.bind.bind(this);
        this.map = this.map.bind(this);
    }

    bind<K extends keyof Val>(name: K): ChildDataBinder<Val, K> {
        if (!(name in this._cache)) {
            this._cache[name] = new ChildDataBinder(this, name);
        }
        return this._cache[name] as ChildDataBinder<Val, K>;
    }

    map<K extends keyof Val, Mapped>(name: K, map: {(binder: ChildDataBinder<Val, K>): Mapped}): Mapped {
        return map(this.bind(name));
    }

    onChange<E = unknown>(_e: E, v: Val) {
        this.change(v);
    }

    inputProps<CV extends Val, E = unknown>(defaultValue?: CV): InputProps<Val, CV, E> {
        const {value, onChange} = this;
        return {
            value: defaultValue !== undefined && value === undefined ? defaultValue : value,
            onChange,
        }
    }

    mapInputProps<K extends keyof Val, CV extends Val[K], E = unknown>(name: K, defaultValue?: CV): InputProps<Val[K], CV, E> { 
        return this.map(name, b => b.inputProps(defaultValue));
    }
}


class RootDataBinder<Val> extends BaseDataBinder<Val, never> {
    value: Val;
    _onChangeData: {(value: Val): void};
    readonly keyPath: string;
    readonly isRoot: true;

    constructor(value: Val, onChangeData: {(value: Val): void}) {
        super();
        this.value = value;
        this._onChangeData = onChangeData;
        this.keyPath = '';
        this.isRoot = true;

        this.update = this.update.bind(this);
        this.assign = this.assign.bind(this);
        this.change = this.change.bind(this);
        this.makeKeyPath = this.makeKeyPath.bind(this);
    }

    get parent(): DataBinder<never> {
        throw new Error('Can not access `parent` for root data binder.');
    }

    update(value: Val, onChangeData: {(value: Val): void}) {
        this.value = value;
        this._onChangeData = onChangeData;
    }

    assign(delta: Partial<Val>): Val {
        const newValue = Object.assign({}, this.value, delta);
        this._onChangeData(newValue);
        return newValue;
    }

    change(newValue: Val, _sideEffect?: Partial<never>): void {
        this._onChangeData(newValue);
    }

    makeKeyPath(name: keyof Val): string {
        return name as string;
    }
}

class ChildDataBinder<Parent, Name extends keyof Parent> extends BaseDataBinder<Parent[Name], Parent> {
    readonly parent: DataBinder<Parent>;
    readonly name: Name;
    readonly keyPath: string;
    readonly isRoot: false;

    constructor(parent: DataBinder<Parent>, name: Name) {
        super();
        this.parent = parent;
        this.name = name;
        this.keyPath = parent.makeKeyPath(name);
        this.isRoot = false;

        this.assign = this.assign.bind(this);
        this.change = this.change.bind(this);
        this.makeKeyPath = this.makeKeyPath.bind(this);
    }

    get value(): Parent[Name] {
        return this.parent.value[this.name];
    }

    assign(delta: Partial<Parent[Name]>): Parent[Name] {
        const {name, value, parent} = this;
        const base = isArray(value) ? [] : {}
        const val = Object.assign(base, value, delta);
        return parent.assign({[name]: val} as unknown as Partial<Parent>)[name];
    }

    change(newValue: Parent[Name], sideEffect?: Partial<Parent>): void {
        const {name, parent} = this;
        parent.assign(Object.assign({[name]: newValue}, sideEffect));
    }

    makeKeyPath(name: keyof Parent[Name]): string {
        return `${this.keyPath}.${name}`;
    }
}


interface OnChangeProps<V, ChangeV extends V, Parent = unknown> {
    sideEffect?(newValue: ChangeV, oldValue: V): Partial<Parent>;
    shouldChange?(newValue: ChangeV, oldValue: V): boolean;
    didChange?(newValue: ChangeV, oldValue: V): void;
}

export const useOnChange = <V, ChangeV extends V, Parent, E = unknown>(binder: DataBinder<V>, props: OnChangeProps<V, ChangeV, Parent>): {(e: E, value: ChangeV): void} => {
    const ref = React.useRef(props);
    ref.current = props;

    return React.useCallback((_e: unknown, newValue: ChangeV): void => {
        const {shouldChange, sideEffect, didChange} = ref.current;
        const oldValue = binder.value;
        if (!equals(newValue, oldValue) && shouldChange?.(newValue, oldValue) !== false) {
            const effect = sideEffect?.(newValue, oldValue) || {};
            binder.change(newValue, effect);
            didChange?.(newValue, oldValue);
        }
    }, [binder]);
};


export const useInputProps = <V, ChangeV extends V, Parent, E = unknown>(binder: DataBinder<V>, props: OnChangeProps<V, ChangeV, Parent>): InputProps<V, ChangeV, E> => {
    return {
        value: binder.value,
        onChange: useOnChange(binder, props),
    };
};
