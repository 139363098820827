import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: string;
  /** Image for input */
  InputImage: any;
  /**
   * PK Scalar Type
   *
   * This is a per model primary key.
   */
  PK: string;
};

export type AcceptTeamInvitationInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptTeamInvitationPayload = {
  __typename?: 'AcceptTeamInvitationPayload';
  result: AcceptTeamInvitationResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptTeamInvitationResult = Team | ValidationError | OperationFailed;

export type AcceptWorkspaceInvitationInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptWorkspaceInvitationPayload = {
  __typename?: 'AcceptWorkspaceInvitationPayload';
  result: AcceptWorkspaceInvitationResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AcceptWorkspaceInvitationResult = Workspace | ValidationError | OperationFailed;

export type AddTeamMemberInput = {
  teamId: Scalars['PK'];
  targetUserId: Scalars['PK'];
  role: Role;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddTeamMemberPayload = {
  __typename?: 'AddTeamMemberPayload';
  result: AddTeamMemberResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddTeamMemberResult = TeamMember | ValidationError | OperationFailed;

export type AddWorkspaceMemberInput = {
  workspaceId: Scalars['PK'];
  profileId: Scalars['PK'];
  role: Role;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddWorkspaceMemberPayload = {
  __typename?: 'AddWorkspaceMemberPayload';
  result: AddWorkspaceMemberResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddWorkspaceMemberResult = WorkspaceMember | ValidationError | OperationFailed;

export type ChangePasswordInput = {
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  result?: Maybe<ChangePasswordResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChangePasswordResult = ValidationError | OperationFailed;

export type CommitEmailChangeInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CommitEmailChangePayload = {
  __typename?: 'CommitEmailChangePayload';
  result?: Maybe<CommitEmailChangeResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CommitEmailChangeResult = ValidationError | OperationFailed;

export type CreateItemInput = {
  workspaceId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  note: Scalars['String'];
  unit: Scalars['String'];
  labelIds: Array<Scalars['PK']>;
  urls: Array<Scalars['String']>;
  barcodes: Array<Scalars['String']>;
  quantities: Array<Maybe<QuantityForStorage>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateItemPayload = {
  __typename?: 'CreateItemPayload';
  result: CreateItemResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateItemResult = Item | ValidationError | OperationFailed;

export type CreateLabelInput = {
  workspaceId: Scalars['PK'];
  name: Scalars['String'];
  color: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLabelPayload = {
  __typename?: 'CreateLabelPayload';
  result: CreateLabelResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLabelResult = Label | ValidationError | OperationFailed;

export type CreateStorageInput = {
  workspaceId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  note: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStoragePayload = {
  __typename?: 'CreateStoragePayload';
  result: CreateStorageResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStorageResult = Storage | ValidationError | OperationFailed;

export type CreateTeamInput = {
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  result: CreateTeamResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTeamResult = Team | ValidationError | OperationFailed;

export type CreateWorkspaceInput = {
  ownerId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  note: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWorkspacePayload = {
  __typename?: 'CreateWorkspacePayload';
  result: CreateWorkspaceResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWorkspaceResult = Workspace | ValidationError | OperationFailed;


export type DeleteItemInput = {
  itemId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteItemPayload = {
  __typename?: 'DeleteItemPayload';
  result?: Maybe<DeleteItemResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteItemResult = ValidationError | OperationFailed;

export type DeleteLabelInput = {
  labelId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLabelPayload = {
  __typename?: 'DeleteLabelPayload';
  result?: Maybe<DeleteLabelResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLabelResult = ValidationError | OperationFailed;

export type DeleteStockInput = {
  stockId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteStockPayload = {
  __typename?: 'DeleteStockPayload';
  result?: Maybe<DeleteStockResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteStockResult = ValidationError | OperationFailed;

export type DeleteStorageInput = {
  storageId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteStoragePayload = {
  __typename?: 'DeleteStoragePayload';
  result?: Maybe<DeleteStorageResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteStorageResult = ValidationError | OperationFailed;

export type DeleteTeamInput = {
  teamId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload';
  result?: Maybe<DeleteTeamResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteTeamResult = ValidationError | OperationFailed;

export type DeleteWorkspaceInput = {
  workspaceId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteWorkspacePayload = {
  __typename?: 'DeleteWorkspacePayload';
  result?: Maybe<DeleteWorkspaceResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteWorkspaceResult = ValidationError | OperationFailed;

export type Image = Node & {
  __typename?: 'Image';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  baseUrl: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
};


export type Item = Node & {
  __typename?: 'Item';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  icon: Image;
  name: Scalars['String'];
  note: Scalars['String'];
  unit: Scalars['String'];
  totalQuantity: Scalars['Float'];
  workspace: Workspace;
  barcodes: Array<Scalars['String']>;
  labels: Array<Label>;
  stocks: Array<Stock>;
};

export type ItemConnection = {
  __typename?: 'ItemConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ItemEdge>>;
};

/** A Relay edge containing a `Item` and its cursor. */
export type ItemEdge = {
  __typename?: 'ItemEdge';
  /** The item at the end of the edge */
  node?: Maybe<Item>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Label = Node & {
  __typename?: 'Label';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  color: Scalars['String'];
  workspace: Workspace;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword?: Maybe<ChangePasswordPayload>;
  commitEmailChange?: Maybe<CommitEmailChangePayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  retrieveEmailFromToken?: Maybe<RetrieveEmailFromTokenPayload>;
  sendEmailForPasswordReset?: Maybe<SendEmailForPasswordResetPayload>;
  sendEmailForSignup?: Maybe<SendEmailForSignupPayload>;
  sendEmailToChangeAddress?: Maybe<SendEmailToChangeAddressPayload>;
  unregisterUser?: Maybe<UnregisterUserPayload>;
  createItem?: Maybe<CreateItemPayload>;
  deleteItem?: Maybe<DeleteItemPayload>;
  updateItem?: Maybe<UpdateItemPayload>;
  createLabel?: Maybe<CreateLabelPayload>;
  deleteLabel?: Maybe<DeleteLabelPayload>;
  updateLabel?: Maybe<UpdateLabelPayload>;
  updateProfile?: Maybe<UpdateProfilePayload>;
  signIn?: Maybe<SignInPayload>;
  signOut?: Maybe<SignOutPayload>;
  deleteStock?: Maybe<DeleteStockPayload>;
  updateStock?: Maybe<UpdateStockPayload>;
  upsertStock?: Maybe<UpsertStockPayload>;
  createStorage?: Maybe<CreateStoragePayload>;
  deleteStorage?: Maybe<DeleteStoragePayload>;
  updateStorage?: Maybe<UpdateStoragePayload>;
  acceptTeamInvitation?: Maybe<AcceptTeamInvitationPayload>;
  addTeamMember?: Maybe<AddTeamMemberPayload>;
  createTeam?: Maybe<CreateTeamPayload>;
  deleteTeam?: Maybe<DeleteTeamPayload>;
  removeTeamMember?: Maybe<RemoveTeamMemberPayload>;
  retrieveTeamFromInvitation?: Maybe<RetrieveTeamFromInvitationPayload>;
  sendTeamInvitation?: Maybe<SendTeamInvitationPayload>;
  updateTeam?: Maybe<UpdateTeamPayload>;
  updateTeamMember?: Maybe<UpdateTeamMemberPayload>;
  acceptWorkspaceInvitation?: Maybe<AcceptWorkspaceInvitationPayload>;
  addWorkspaceMember?: Maybe<AddWorkspaceMemberPayload>;
  createWorkspace?: Maybe<CreateWorkspacePayload>;
  deleteWorkspace?: Maybe<DeleteWorkspacePayload>;
  removeWorkspaceMember?: Maybe<RemoveWorkspaceMemberPayload>;
  retrieveWorkspaceFromInvitation?: Maybe<RetrieveWorkspaceFromInvitationPayload>;
  sendWorkspaceInvitation?: Maybe<SendWorkspaceInvitationPayload>;
  updateWorkspace?: Maybe<UpdateWorkspacePayload>;
  updateWorkspaceMember?: Maybe<UpdateWorkspaceMemberPayload>;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationCommitEmailChangeArgs = {
  input: CommitEmailChangeInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRetrieveEmailFromTokenArgs = {
  input: RetrieveEmailFromTokenInput;
};


export type MutationSendEmailForPasswordResetArgs = {
  input: SendEmailForPasswordResetInput;
};


export type MutationSendEmailForSignupArgs = {
  input: SendEmailForSignupInput;
};


export type MutationSendEmailToChangeAddressArgs = {
  input: SendEmailToChangeAddressInput;
};


export type MutationUnregisterUserArgs = {
  input: UnregisterUserInput;
};


export type MutationCreateItemArgs = {
  input: CreateItemInput;
};


export type MutationDeleteItemArgs = {
  input: DeleteItemInput;
};


export type MutationUpdateItemArgs = {
  input: UpdateItemInput;
};


export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};


export type MutationDeleteLabelArgs = {
  input: DeleteLabelInput;
};


export type MutationUpdateLabelArgs = {
  input: UpdateLabelInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignOutArgs = {
  input: SignOutInput;
};


export type MutationDeleteStockArgs = {
  input: DeleteStockInput;
};


export type MutationUpdateStockArgs = {
  input: UpdateStockInput;
};


export type MutationUpsertStockArgs = {
  input: UpsertStockInput;
};


export type MutationCreateStorageArgs = {
  input: CreateStorageInput;
};


export type MutationDeleteStorageArgs = {
  input: DeleteStorageInput;
};


export type MutationUpdateStorageArgs = {
  input: UpdateStorageInput;
};


export type MutationAcceptTeamInvitationArgs = {
  input: AcceptTeamInvitationInput;
};


export type MutationAddTeamMemberArgs = {
  input: AddTeamMemberInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationRemoveTeamMemberArgs = {
  input: RemoveTeamMemberInput;
};


export type MutationRetrieveTeamFromInvitationArgs = {
  input: RetrieveTeamFromInvitationInput;
};


export type MutationSendTeamInvitationArgs = {
  input: SendTeamInvitationInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationUpdateTeamMemberArgs = {
  input: UpdateTeamMemberInput;
};


export type MutationAcceptWorkspaceInvitationArgs = {
  input: AcceptWorkspaceInvitationInput;
};


export type MutationAddWorkspaceMemberArgs = {
  input: AddWorkspaceMemberInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationDeleteWorkspaceArgs = {
  input: DeleteWorkspaceInput;
};


export type MutationRemoveWorkspaceMemberArgs = {
  input: RemoveWorkspaceMemberInput;
};


export type MutationRetrieveWorkspaceFromInvitationArgs = {
  input: RetrieveWorkspaceFromInvitationInput;
};


export type MutationSendWorkspaceInvitationArgs = {
  input: SendWorkspaceInvitationInput;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};


export type MutationUpdateWorkspaceMemberArgs = {
  input: UpdateWorkspaceMemberInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type OperationFailed = {
  __typename?: 'OperationFailed';
  message: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};


/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type Profile = Node & {
  __typename?: 'Profile';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  icon: Image;
  name: Scalars['String'];
  owner: ProfileOwner;
};

export type ProfileOwner = User | Team;

export type QuantityForStorage = {
  storageId: Scalars['PK'];
  quantity: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  viewer?: Maybe<Viewer>;
  node?: Maybe<Node>;
  profile?: Maybe<Profile>;
  user?: Maybe<User>;
  team?: Maybe<Team>;
  teamMember?: Maybe<TeamMember>;
  workspace?: Maybe<Workspace>;
  workspaceMember?: Maybe<WorkspaceMember>;
  storage?: Maybe<Storage>;
  item?: Maybe<Item>;
  label?: Maybe<Label>;
  stock?: Maybe<Stock>;
  stockLog?: Maybe<StockLog>;
  items?: Maybe<ItemConnection>;
  stocks?: Maybe<StockConnection>;
  recentUpdatedStocks?: Maybe<StockConnection>;
  stockLogs?: Maybe<StockLogConnection>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryProfileArgs = {
  id: Scalars['PK'];
};


export type QueryUserArgs = {
  id: Scalars['PK'];
};


export type QueryTeamArgs = {
  id: Scalars['PK'];
};


export type QueryTeamMemberArgs = {
  id: Scalars['PK'];
};


export type QueryWorkspaceArgs = {
  id: Scalars['PK'];
};


export type QueryWorkspaceMemberArgs = {
  id: Scalars['PK'];
};


export type QueryStorageArgs = {
  id: Scalars['PK'];
};


export type QueryItemArgs = {
  id: Scalars['PK'];
};


export type QueryLabelArgs = {
  id: Scalars['PK'];
};


export type QueryStockArgs = {
  id: Scalars['PK'];
};


export type QueryStockLogArgs = {
  id: Scalars['PK'];
};


export type QueryItemsArgs = {
  workspaceIds?: Maybe<Array<Scalars['PK']>>;
  keyword?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  labelIds?: Maybe<Array<Scalars['PK']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStocksArgs = {
  workspaceIds?: Maybe<Array<Scalars['PK']>>;
  storageIds?: Maybe<Array<Scalars['PK']>>;
  itemIds?: Maybe<Array<Scalars['PK']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRecentUpdatedStocksArgs = {
  workspaceIds?: Maybe<Array<Scalars['PK']>>;
  storageIds?: Maybe<Array<Scalars['PK']>>;
  itemIds?: Maybe<Array<Scalars['PK']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryStockLogsArgs = {
  workspaceIds?: Maybe<Array<Scalars['PK']>>;
  storageIds?: Maybe<Array<Scalars['PK']>>;
  itemIds?: Maybe<Array<Scalars['PK']>>;
  stockIds?: Maybe<Array<Scalars['PK']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RegisterUserInput = {
  token: Scalars['String'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  result: RegisterUserResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserResult = Viewer | ValidationError | OperationFailed;

export type RemoveTeamMemberInput = {
  teamId: Scalars['PK'];
  memberId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveTeamMemberPayload = {
  __typename?: 'RemoveTeamMemberPayload';
  result?: Maybe<RemoveTeamMemberResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveTeamMemberResult = ValidationError | OperationFailed;

export type RemoveWorkspaceMemberInput = {
  workspaceId: Scalars['PK'];
  memberId: Scalars['PK'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveWorkspaceMemberPayload = {
  __typename?: 'RemoveWorkspaceMemberPayload';
  result?: Maybe<RemoveWorkspaceMemberResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveWorkspaceMemberResult = ValidationError | OperationFailed;

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  result?: Maybe<ResetPasswordResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordResult = ValidationError | OperationFailed;

export type RetrieveEmailFromTokenInput = {
  token: Scalars['String'];
  checkUsed: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RetrieveEmailFromTokenPayload = {
  __typename?: 'RetrieveEmailFromTokenPayload';
  result: RetrieveEmailFromTokenResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RetrieveEmailFromTokenResult = TokenWithEmail | ValidationError | OperationFailed;

export type RetrieveTeamFromInvitationInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RetrieveTeamFromInvitationPayload = {
  __typename?: 'RetrieveTeamFromInvitationPayload';
  result: RetrieveTeamFromInvitationResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RetrieveTeamFromInvitationResult = Team | ValidationError | OperationFailed;

export type RetrieveWorkspaceFromInvitationInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RetrieveWorkspaceFromInvitationPayload = {
  __typename?: 'RetrieveWorkspaceFromInvitationPayload';
  result: RetrieveWorkspaceFromInvitationResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RetrieveWorkspaceFromInvitationResult = Workspace | ValidationError | OperationFailed;

/** An enumeration. */
export enum Role {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member'
}

export type SendEmailForPasswordResetInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailForPasswordResetPayload = {
  __typename?: 'SendEmailForPasswordResetPayload';
  result?: Maybe<SendEmailForPasswordResetResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailForPasswordResetResult = ValidationError | OperationFailed;

export type SendEmailForSignupInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailForSignupPayload = {
  __typename?: 'SendEmailForSignupPayload';
  result?: Maybe<SendEmailForSignupResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailForSignupResult = ValidationError | OperationFailed;

export type SendEmailToChangeAddressInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailToChangeAddressPayload = {
  __typename?: 'SendEmailToChangeAddressPayload';
  result?: Maybe<SendEmailToChangeAddressResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendEmailToChangeAddressResult = ValidationError | OperationFailed;

export type SendTeamInvitationInput = {
  teamId: Scalars['PK'];
  email: Scalars['String'];
  role: Role;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendTeamInvitationPayload = {
  __typename?: 'SendTeamInvitationPayload';
  result?: Maybe<SendTeamInvitationResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendTeamInvitationResult = ValidationError | OperationFailed;

export type SendWorkspaceInvitationInput = {
  workspaceId: Scalars['PK'];
  email: Scalars['String'];
  role: Role;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendWorkspaceInvitationPayload = {
  __typename?: 'SendWorkspaceInvitationPayload';
  result?: Maybe<SendWorkspaceInvitationResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendWorkspaceInvitationResult = ValidationError | OperationFailed;

export type SignInInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SignInPayload = {
  __typename?: 'SignInPayload';
  result: SignInResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SignInResult = Viewer | ValidationError | OperationFailed;

export type SignOutInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  result: SignOutResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SignOutResult = Viewer | ValidationError | OperationFailed;

export type Stock = Node & {
  __typename?: 'Stock';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  item: Item;
  storage: Storage;
  quantity: Scalars['Float'];
  logs: Array<StockLog>;
};

export type StockConnection = {
  __typename?: 'StockConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StockEdge>>;
};

/** A Relay edge containing a `Stock` and its cursor. */
export type StockEdge = {
  __typename?: 'StockEdge';
  /** The item at the end of the edge */
  node?: Maybe<Stock>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StockLog = Node & {
  __typename?: 'StockLog';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stock: Stock;
  difference: Scalars['Float'];
  comment: Scalars['String'];
};

export type StockLogConnection = {
  __typename?: 'StockLogConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StockLogEdge>>;
};

/** A Relay edge containing a `StockLog` and its cursor. */
export type StockLogEdge = {
  __typename?: 'StockLogEdge';
  /** The item at the end of the edge */
  node?: Maybe<StockLog>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type Storage = Node & {
  __typename?: 'Storage';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  icon: Image;
  name: Scalars['String'];
  note: Scalars['String'];
  workspace: Workspace;
  stocks: Array<Stock>;
};

export type Team = Node & {
  __typename?: 'Team';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  profile: Profile;
  memberships: Array<TeamMember>;
};

export type TeamMember = Node & {
  __typename?: 'TeamMember';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  team: Team;
  role: Role;
};

export type TokenWithEmail = {
  __typename?: 'TokenWithEmail';
  email?: Maybe<Scalars['String']>;
};

export type UnregisterUserInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnregisterUserPayload = {
  __typename?: 'UnregisterUserPayload';
  result?: Maybe<UnregisterUserResult>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnregisterUserResult = ValidationError | OperationFailed;

export type UpdateItemInput = {
  itemId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  note: Scalars['String'];
  unit: Scalars['String'];
  labelIds: Array<Scalars['PK']>;
  urls: Array<Scalars['String']>;
  barcodes: Array<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateItemPayload = {
  __typename?: 'UpdateItemPayload';
  result: UpdateItemResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateItemResult = Item | ValidationError | OperationFailed;

export type UpdateLabelInput = {
  labelId: Scalars['PK'];
  name: Scalars['String'];
  color: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLabelPayload = {
  __typename?: 'UpdateLabelPayload';
  result: UpdateLabelResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLabelResult = Label | ValidationError | OperationFailed;

export type UpdateProfileInput = {
  profileId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProfilePayload = {
  __typename?: 'UpdateProfilePayload';
  result: UpdateProfileResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProfileResult = Profile | ValidationError | OperationFailed;

export type UpdateStockInput = {
  stockId: Scalars['PK'];
  quantity: Scalars['Float'];
  comment: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStockPayload = {
  __typename?: 'UpdateStockPayload';
  result: UpdateStockResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStockResult = Stock | ValidationError | OperationFailed;

export type UpdateStorageInput = {
  storageId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  note: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStoragePayload = {
  __typename?: 'UpdateStoragePayload';
  result: UpdateStorageResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStorageResult = Storage | ValidationError | OperationFailed;

export type UpdateTeamInput = {
  teamId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTeamMemberInput = {
  teamId: Scalars['PK'];
  memberId: Scalars['PK'];
  role: Role;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTeamMemberPayload = {
  __typename?: 'UpdateTeamMemberPayload';
  result: UpdateTeamMemberResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTeamMemberResult = TeamMember | ValidationError | OperationFailed;

export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload';
  result: UpdateTeamResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTeamResult = Team | ValidationError | OperationFailed;

export type UpdateWorkspaceInput = {
  workspaceId: Scalars['PK'];
  icon: Scalars['InputImage'];
  name: Scalars['String'];
  note: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWorkspaceMemberInput = {
  workspaceId: Scalars['PK'];
  memberId: Scalars['PK'];
  role: Role;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWorkspaceMemberPayload = {
  __typename?: 'UpdateWorkspaceMemberPayload';
  result: UpdateWorkspaceMemberResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWorkspaceMemberResult = WorkspaceMember | ValidationError | OperationFailed;

export type UpdateWorkspacePayload = {
  __typename?: 'UpdateWorkspacePayload';
  result: UpdateWorkspaceResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWorkspaceResult = Workspace | ValidationError | OperationFailed;

export type UpsertStockInput = {
  storageId: Scalars['PK'];
  itemId: Scalars['PK'];
  quantity: Scalars['Float'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertStockPayload = {
  __typename?: 'UpsertStockPayload';
  result: UpsertStockResult;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertStockResult = Stock | ValidationError | OperationFailed;

export type User = Node & {
  __typename?: 'User';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  profile: Profile;
};

export type ValidationError = {
  __typename?: 'ValidationError';
  items: Array<ValidationErrorItem>;
};

export type ValidationErrorItem = {
  __typename?: 'ValidationErrorItem';
  keypath: Array<Scalars['String']>;
  message: Scalars['String'];
  code?: Maybe<Scalars['String']>;
};

export type Viewer = {
  __typename?: 'Viewer';
  email: Scalars['String'];
  user: User;
  teams: Array<Team>;
  workspaces: Array<Workspace>;
};

export type Workspace = Node & {
  __typename?: 'Workspace';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  icon: Image;
  name: Scalars['String'];
  note: Scalars['String'];
  storages: Array<Storage>;
  items?: Maybe<ItemConnection>;
  memberships: Array<WorkspaceMember>;
};


export type WorkspaceItemsArgs = {
  workspaceIds?: Maybe<Array<Scalars['PK']>>;
  keyword?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  labelIds?: Maybe<Array<Scalars['PK']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type WorkspaceMember = Node & {
  __typename?: 'WorkspaceMember';
  /** The ID of the object. */
  id: Scalars['ID'];
  pk: Scalars['PK'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  member: ProfileOwner;
  workspace: Workspace;
  role: Role;
};

export type GetRecentUpdatedStocksQueryVariables = Exact<{
  id: Scalars['PK'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetRecentUpdatedStocksQuery = { __typename?: 'Query', recentUpdatedStocks?: Maybe<{ __typename?: 'StockConnection', edges: Array<Maybe<{ __typename?: 'StockEdge', node?: Maybe<{ __typename?: 'Stock', id: string, pk: string, quantity: number, item: { __typename?: 'Item', name: string, icon: { __typename?: 'Image', baseUrl: string } }, storage: { __typename?: 'Storage', name: string } }> }>>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: Maybe<string> } }> };


export const GetRecentUpdatedStocksDocument = gql`
    query GetRecentUpdatedStocks($id: PK!, $after: String) {
  recentUpdatedStocks(workspaceIds: [$id], first: 15, after: $after) {
    edges {
      node {
        id
        pk
        quantity
        item {
          icon {
            baseUrl
          }
          name
        }
        storage {
          name
        }
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;

/**
 * __useGetRecentUpdatedStocksQuery__
 *
 * To run a query within a React component, call `useGetRecentUpdatedStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentUpdatedStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentUpdatedStocksQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetRecentUpdatedStocksQuery(baseOptions: Apollo.QueryHookOptions<GetRecentUpdatedStocksQuery, GetRecentUpdatedStocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentUpdatedStocksQuery, GetRecentUpdatedStocksQueryVariables>(GetRecentUpdatedStocksDocument, options);
      }
export function useGetRecentUpdatedStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentUpdatedStocksQuery, GetRecentUpdatedStocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentUpdatedStocksQuery, GetRecentUpdatedStocksQueryVariables>(GetRecentUpdatedStocksDocument, options);
        }
export type GetRecentUpdatedStocksQueryHookResult = ReturnType<typeof useGetRecentUpdatedStocksQuery>;
export type GetRecentUpdatedStocksLazyQueryHookResult = ReturnType<typeof useGetRecentUpdatedStocksLazyQuery>;
export type GetRecentUpdatedStocksQueryResult = Apollo.QueryResult<GetRecentUpdatedStocksQuery, GetRecentUpdatedStocksQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AcceptTeamInvitationResult": [
      "Team",
      "ValidationError",
      "OperationFailed"
    ],
    "AcceptWorkspaceInvitationResult": [
      "Workspace",
      "ValidationError",
      "OperationFailed"
    ],
    "AddTeamMemberResult": [
      "TeamMember",
      "ValidationError",
      "OperationFailed"
    ],
    "AddWorkspaceMemberResult": [
      "WorkspaceMember",
      "ValidationError",
      "OperationFailed"
    ],
    "ChangePasswordResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "CommitEmailChangeResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "CreateItemResult": [
      "Item",
      "ValidationError",
      "OperationFailed"
    ],
    "CreateLabelResult": [
      "Label",
      "ValidationError",
      "OperationFailed"
    ],
    "CreateStorageResult": [
      "Storage",
      "ValidationError",
      "OperationFailed"
    ],
    "CreateTeamResult": [
      "Team",
      "ValidationError",
      "OperationFailed"
    ],
    "CreateWorkspaceResult": [
      "Workspace",
      "ValidationError",
      "OperationFailed"
    ],
    "DeleteItemResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "DeleteLabelResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "DeleteStockResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "DeleteStorageResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "DeleteTeamResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "DeleteWorkspaceResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "Node": [
      "Image",
      "Item",
      "Label",
      "Profile",
      "Stock",
      "StockLog",
      "Storage",
      "Team",
      "TeamMember",
      "User",
      "Workspace",
      "WorkspaceMember"
    ],
    "ProfileOwner": [
      "User",
      "Team"
    ],
    "RegisterUserResult": [
      "Viewer",
      "ValidationError",
      "OperationFailed"
    ],
    "RemoveTeamMemberResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "RemoveWorkspaceMemberResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "ResetPasswordResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "RetrieveEmailFromTokenResult": [
      "TokenWithEmail",
      "ValidationError",
      "OperationFailed"
    ],
    "RetrieveTeamFromInvitationResult": [
      "Team",
      "ValidationError",
      "OperationFailed"
    ],
    "RetrieveWorkspaceFromInvitationResult": [
      "Workspace",
      "ValidationError",
      "OperationFailed"
    ],
    "SendEmailForPasswordResetResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "SendEmailForSignupResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "SendEmailToChangeAddressResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "SendTeamInvitationResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "SendWorkspaceInvitationResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "SignInResult": [
      "Viewer",
      "ValidationError",
      "OperationFailed"
    ],
    "SignOutResult": [
      "Viewer",
      "ValidationError",
      "OperationFailed"
    ],
    "UnregisterUserResult": [
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateItemResult": [
      "Item",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateLabelResult": [
      "Label",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateProfileResult": [
      "Profile",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateStockResult": [
      "Stock",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateStorageResult": [
      "Storage",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateTeamMemberResult": [
      "TeamMember",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateTeamResult": [
      "Team",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateWorkspaceMemberResult": [
      "WorkspaceMember",
      "ValidationError",
      "OperationFailed"
    ],
    "UpdateWorkspaceResult": [
      "Workspace",
      "ValidationError",
      "OperationFailed"
    ],
    "UpsertStockResult": [
      "Stock",
      "ValidationError",
      "OperationFailed"
    ]
  }
};
      export default result;
    