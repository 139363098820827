import {React} from 'lib';

import {Workspace} from 'types/stock/api/data.gen';
import {useRemoteData, RemoteData} from 'shared';


const WorkspaceContext = React.createContext<Workspace | null>(null);


export const useWorkspace = (): Workspace => {
    const workspace = React.useContext(WorkspaceContext);
    if (!workspace) {
        throw new Error('Can not call `useWorkspace` outside out WorkspaceProvider');
    }
    return workspace;
};


export const WorkspaceProvider = ({workspace, children}: {
    workspace: Workspace;
    children: React.ReactNode;
}): React.ReactElement => {
    return <WorkspaceContext.Provider value={workspace}>
        <LabelDataProvidor workspace={workspace}>
            {children}
        </LabelDataProvidor>
    </WorkspaceContext.Provider>;
}


export type LabelData = RemoteData<'get_labels'>;

const LabelDataContext = React.createContext<LabelData | null>(null);

const LabelDataProvidor = ({children, workspace}: {
    children: React.ReactNode;
    workspace: Workspace;
}): React.ReactElement => {
    const data = useRemoteData({
        path: 'get_labels',
        request: {
            workspace_id: workspace.id,
        },
    });
    return <LabelDataContext.Provider value={data}>
        {children}
    </LabelDataContext.Provider>;
};

export const useLabelData = (): LabelData => {
    const data = React.useContext(LabelDataContext);
    if (!data) {
        throw new Error('Can not call `useLabelData` outside out WorkspaceProvider');
    }
    return data;
}
