import {React} from 'lib';

import {Loading, useRemoteData} from 'shared';
import {GenericList, DefaultGenericListProps} from 'components/generic/list';
import {Workspace} from 'types/stock/api/data.gen';



export const WorkspaceList = React.forwardRef<{
    reload(): void;
}, {
    profileIds?: string[];
} & DefaultGenericListProps<Workspace>>(({profileIds, ...other}, ref): React.ReactElement => {
    const api = useRemoteData({
        path: 'list_workspaces',
        request: {
            profile_ids: profileIds,
        },
        reloadWhenFailed: true,
    });

    React.useImperativeHandle(ref, () => ({
        reload: api.reload,
    }));

    if (api.state !== 'success') {
        return <Loading />
    }

    return <GenericList
        {...other}
        items={api.data.items}
        radius="4px"
    />
});
