import {React, ui, icons, Router, urls} from 'lib';

import {LoadingScreen, useRemoteData, useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, LayoutItem, ImageInput, imgurl, S3ImageWithPreview, RouterTextLink, AlertDialog, useForm, input, EmptyText, Section} from 'shared';
import {StockList} from 'components/stock/list_for_item';
import {StockSettingModal} from 'components/stock/setting';
import {Labels, LabelListInput} from 'components/label';
import {BarcodeListInput} from 'components/barcode';
import {withFrame} from 'components/frame';
import {WorkspaceProvider} from 'components/workspace/context';
import {Item} from 'types/stock/api/data.gen';


export const ItemDetail = withFrame(({itemId}: {
    itemId: string;
}): React.ReactElement => {
    const history = Router.useHistory();
    const moveToParent = () => history.replace('/');
    const api = useRemoteData({
        path: 'get_item',
        request: {item_id: itemId},
        reloadWhenFailed: true,
        onClientError: moveToParent,
    });
    const editionDisclosure = ui.useDisclosure();
    const stockDisclosure = ui.useDisclosure();
    const stockListRef = React.useRef<Ref<typeof StockList>>(null);

    if (!api.data) {
        return <LoadingScreen />
    }

    const {workspace, item} = api.data;

    return <WorkspaceProvider workspace={workspace}>
        <Section>
            <LayoutItem>
                <ui.HStack>
                    <ui.Text>
                        <RouterTextLink to={urls.home()}>HOME</RouterTextLink>
                    </ui.Text>

                    <ui.Text>
                        {'>'} <RouterTextLink to={urls.workspace(workspace.id)}>{workspace.name}</RouterTextLink>
                    </ui.Text>
                </ui.HStack>
            </LayoutItem>

            <LayoutItem.Group space={4}>
                <LayoutItem>
                    <ui.Box>
                        <ui.HStack width="100%">
                            <S3ImageWithPreview
                                base={item.icon.base_url}
                                processor="cov128"
                                borderRadius="4px"
                                width="64px"
                                height="64px"
                                mr={2}
                                previewTitle={item.name}
                            />

                            <ui.Heading size="xl">
                                {item.name}
                            </ui.Heading>

                            <ui.Spacer />

                            <ui.IconButton
                                onClick={editionDisclosure.onOpen}
                                icon={<icons.EditIcon />}
                                aria-label="edit-item"
                            />
                        </ui.HStack>
                    </ui.Box>
                </LayoutItem>

                {item.labels.length > 0 && <LayoutItem>
                    <Labels labels={item.labels} />
                </LayoutItem>}

                {!!item.note && <LayoutItem>
                    <ui.Text whiteSpace="pre-line" fontSize="sm">
                        {item.note}
                    </ui.Text>
                </LayoutItem>}

                <LayoutItem>
                    <ui.HStack>
                        <ui.Button
                            colorScheme="blue"
                            onClick={stockDisclosure.onOpen}
                            leftIcon={<icons.EditIcon />}
                        >
                            在庫の設定
                        </ui.Button>
                    </ui.HStack>
                </LayoutItem>
            </LayoutItem.Group>
        </Section>

        <Section title="在庫一覧">
            <StockList
                ref={stockListRef}
                item={item}
                empty={<EmptyText>
                    {item.name}には在庫が登録されていません
                </EmptyText>}
            />
        </Section>

        <StockSettingModal
            {...stockDisclosure}
            item={item}
            onComplete={() => {
                stockListRef.current?.reload();
                stockDisclosure.onClose();
            }}
            onCompleteAndThen={() => {
                stockListRef.current?.reload();
            }}
        />

        <ItemEditionModal
            {...editionDisclosure}
            item={item}
            onComplete={() => {
                api.reload();
                editionDisclosure.onClose();
            }}
            onDeleted={moveToParent}
        />
    </WorkspaceProvider>
});


export const ItemEditionModal = ({
    item,
    onComplete,
    onDeleted,
    ...props
}: {
    item: Item;
    onComplete(item: Item): void;
    onDeleted(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_item';
    const api = useWriterApi(path);
    const deleteApi = useWriterApi('delete_item');

    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        item_id: item.id,
        icon: imgurl(item.icon.base_url, 'cov128'),
        name: item.name,
        note: item.note,
        unit: item.unit,
        label_ids: item.labels.map(l => l.id),
        urls: item.urls,
        barcodes: item.barcodes,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [reset, props.isOpen]);

    const submit = handleSubmit(api.call);
    const confirmDisclosure = ui.useDisclosure();

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">アイテムの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名称"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：洗剤、歯ブラシ、コピー用紙"
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="4px"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>

                    <FormItem
                        label="メモ"
                        keyPath="note"
                        error={api.error?.data}
                    >
                        <input.Textarea
                            {...binder.mapInputProps('note')}
                            placeholder=""
                        />
                    </FormItem>

                    <FormItem
                        label="単位"
                        keyPath="unit"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('unit')}
                            placeholder="例：個、本、箱"
                        />
                    </FormItem>

                    <FormItem label="ラベル">
                        <LabelListInput
                            {...binder.mapInputProps('label_ids')}
                        />
                    </FormItem>

                    <FormItem label="バーコード">
                        <BarcodeListInput
                            {...binder.mapInputProps('barcodes')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                api.reset();
            }}
        />

        <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={() => deleteApi.call({item_id: item.id}).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text color="red">
                    {item.name}を操作した履歴も削除されます
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    この操作は取り消せません。<br />
                    本当に削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>
        <ApiSpinnerDialog api={deleteApi} />
        <ApiCompletionDialog
            message="データを削除しました"
            api={deleteApi}
            onOk={onDeleted}
        />
    </ui.Modal>;
};
