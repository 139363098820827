import {React, ui} from 'lib';

import {Section} from 'shared';


type TermLine = {
    indent: number;
    text: string;
};

type SectionType = {
    title: string;
    headingLevel: number;
    lines: TermLine[];
}

const useParsed = (content: string): SectionType[] => {
    return React.useMemo(() => {
        const sections = content.trim().split(/(?=(\n|^)#+ *)/g);
        const headingRegex = /^(#+) */;
        const marginRegex = /^( +)/;
        return sections.map((section) => {
            const [title, ...lines] = section.trim().split(/\n/);
            const heading = title.match(headingRegex);
            return {
                title: title.replace(headingRegex, ''),
                headingLevel: heading?.[1].length ?? 0,
                lines: lines.map((l) => {
                    return {
                        indent: (l.match(marginRegex)?.[1].length ?? 0) / 4,
                        text: l.trim(),
                    };
                }),
            };
        });
    }, [content]);
};


export const Terms = ({content}: {
    content: string;
}): React.ReactElement => {
    const parsed = useParsed(content);

    return <>
        {parsed.map((section, i) => <TermSection key={i} section={section} />)}
    </>
};


const TermSection = ({section}: {section: SectionType}) => {
    return <Section
        title={section.title}
        titleSize={section.headingLevel === 1 ? 'xl' : 'lg'}
    >
        {section.lines.map((line, j) => <LineText line={line} key={j} />)}
    </Section>
};


const LineText = ({line}: {line: TermLine}) => {
    return <ui.Text
        ml={line.indent * 2}
        mt={line.text === '' ? 8 : 0}
        fontSize="md"
        lineHeight="taller"
    >
        {line.text}
    </ui.Text>;
};


export const asTermsModal = (content: string) => (props: Excluded<Props<typeof TermsModal> ,'content'>): React.ReactElement => <TermsModal content={content} {...props} />;


export const TermsModal = ({content, ...props}: {
    content: string;
} & Pick<ui.ModalProps, 'isOpen' | 'onClose'>) => {
    const [header, ...sections] = useParsed(content);
    return <ui.Modal size="xl" {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">{header.title}</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <Section>
                    {header.lines.map((line, j) => <LineText line={line} key={j} />)}
                </Section>

                {sections.map((section, i) => <TermSection key={i} section={section} />)}
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose}>
                    閉じる
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};
