import {React, ui, compressImage, icons} from 'lib';

import {PROCESSOR_NAMES} from 'types/stock/images.gen';
import {InputProps} from './binder';


export function imgurl(base: string, processor?: PROCESSOR_NAMES): string;
export function imgurl(base: string | null | undefined, processor?: PROCESSOR_NAMES): string | undefined;
export function imgurl(base: string | null | undefined, processor?: PROCESSOR_NAMES): string | undefined {
    return base ? base + (processor ?? '') : undefined;
};


export const S3Image = ({
    base,
    processor,
    ...props
}: {
    base?: string | null;
    processor?: PROCESSOR_NAMES;
} & Excluded<Props<typeof ui.Image>, 'src'>): React.ReactElement => {

    return <ui.Image
        flexGrow={0}
        flexShrink={0}
        maxWidth="100%"
        maxHeight="100%"
        src={base ? imgurl(base, processor) : placeholder}
        {...props}
    />;
};


export const S3ImageWithPreview = ({
    base,
    previewProcessor,
    previewTitle,
    ...props
}: {
    previewProcessor?: PROCESSOR_NAMES;
    previewTitle?: string;
} & Props<typeof S3Image>): React.ReactElement => {
    const disclosure = ui.useDisclosure();

    return <>
        <S3Image
            base={base}
            {...props}
            onClick={disclosure.onOpen}
            cursor={base ? 'pointer' : undefined}
        />

        {base && <S3ImageDialog
            title={previewTitle}
            base={base}
            processor={previewProcessor ?? 'con1024'}
            {...disclosure}
        />}
    </>;
};


export const useGeneratedImage = (width?: number, height?: number, color?: string): string => {
    return React.useMemo(
        () => generateImage(width, height, color),
        [width, height, color]);
}


const placeholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEXt8vcDE5qpAAAACklEQVR4nGNgAAAAAgABSK+kcQAAAABJRU5ErkJggg==';


export const generateImage = (width: number = 640, height: number = 640, color?: string): string => {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.fillStyle = color ?? generateRandomColor();
        ctx.fillRect(0, 0, width, height);
    }
    return canvas.toDataURL(`image/jpeg`);
};


export const generateRandomColor = (): string => {
    const [r, g, b] = generateRandomRgb();
    return rgbToCode(r, g, b);
}


export const rgbToCode = (r: number, g: number, b: number): string => {
    return '#' + [r, g, b].map(v => (v < 16 ? '0' : '') + v.toString(16)).join('');
};


export const generateRandomRgb = (): [number, number, number] => {
    const [h, s, l] = generateRandomHsl();
    const [r, g, b] = hslToRgb(h / 360, s / 100, l / 100);
    return [r, g, b];
};


export const generateRandomHsl = (): [number, number, number] => {
    const randomInt = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const h = randomInt(0, 360);
    const s = randomInt(42, 98);
    const l = randomInt(40, 86);
    return [h, s, l];
};


export function fontColor(rgb: string): string;
export function fontColor(r: number, g: number, b: number): [number, number, number];
export function fontColor(r: number | string, g?: number, b?: number): string | [number, number, number] {
    if (typeof r === 'string') {
        return isDarkColor(r) ? '#ffffff' : '#000000';
    } else {
        return isDarkColor(r, g ?? 0, b ??0) ? [255, 255, 255] : [0, 0, 0];
    }
}


export function isDarkColor(rgb: string): boolean;
export function isDarkColor(r: number, g: number, b: number): boolean;
export function isDarkColor(r: number | string, g?: number, b?: number): boolean {
    if (typeof r === 'string') {
        const [a, b, c] = r.replace('#', '').match(/.{1,2}/g)?.map(p => parseInt(p, 16)) ?? [0, 0, 0];
        return isDarkColor(a, b, c)
    }
    return r * 0.299 + (g ?? 0) * 0.587 + (b ?? 0) * 0.114 <= 186;
};


const hslToRgb = (h: number, s: number, l: number): [number, number, number] => {
    var r, g, b;

    if (s === 0) {
        r = g = b = l;
    }else{
        var hue2rgb = function hue2rgb(p: number, q: number, t: number): number {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1/6) return p + (q - p) * 6 * t;
            if (t < 1/2) return q;
            if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}


const makeInitial = (name: string): string => {
    const parts = name.split(/\s+/).filter(p => !!p);
    if (parts.length > 1) {
        return (parts[0][0] + parts[parts.length - 1][0]).toUpperCase();
    }
    return name.substr(0, 2).toUpperCase();
};


export const generateInitialImage = (name: string): string => {
    const rgb = generateRandomRgb();
    const bg = rgbToCode(...rgb);
    const color = fontColor(bg);
    const width = 640;
    const height = 640;

    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.fillStyle = bg;
        ctx.fillRect(0, 0, width, height);

        ctx.font = `${width / 2 - 20}px system-ui`;
        ctx.fillStyle = color;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(makeInitial(name), width / 2, height / 2);
    }
    return canvas.toDataURL(`image/jpeg`);
};



export const ImageInput = ({
    value,
    onChange,
    maxSizeMB = 1,
    maxWidthOrHeight = 2048,
    nameForInitial,
    ...props
}: InputProps<string | null | undefined, string> & {
    maxSizeMB?: number;
    maxWidthOrHeight?: number;
    nameForInitial?: string;
} & Excluded<Props<typeof ui.Img>, 'value' | 'onChange' | 'src' | 'onClick'>): React.ReactElement => {
    const ref = React.useRef<HTMLInputElement>(null);
    const pick = () => ref.current?.click();

    return <ui.Box>
        <input
            ref={ref}
            type="file"
            accept="image/*"
            style={{display: 'none'}}
            onChange={async (e) => {
                const files = Array.from(e.target.files ?? []);
                if (files.length === 0) {
                    return;
                }
                onChange(e, await compressImage.getDataUrlFromFile(await compressImage(files[0], {
                    maxSizeMB,
                    maxWidthOrHeight,
                })));
            }}
            multiple={false}
        />

        <ui.HStack>
            <ui.Img
                src={value ?? placeholder}
                objectFit="cover"
                flexGrow={0}
                flexShrink={0}
                maxWidth="100%"
                maxHeight="100%"
                {...props}
            />

            <ui.Spacer />

            <ui.Box>
                <ui.Menu>
                    <ui.MenuButton as={ui.Button}>
                        {value ? '変更' : '設定'}
                    </ui.MenuButton>
                    <ui.MenuList>
                        <ui.MenuItem onClick={pick}>画像を選択</ui.MenuItem>
                        <ui.MenuItem
                            onClick={(e) => onChange(e, generateImage())}
                            closeOnSelect={false}
                        >
                            ランダムカラー
                        </ui.MenuItem>
                        {!!nameForInitial && <ui.MenuItem
                            onClick={(e) => onChange(e, generateInitialImage(nameForInitial))}
                            closeOnSelect={false}
                        >
                            文字 + ランダムカラー
                        </ui.MenuItem>}
                    </ui.MenuList>
                </ui.Menu>
            </ui.Box>
        </ui.HStack>
    </ui.Box>
};



export const ColorInput = ({
    value,
    onChange,
}: InputProps<string | null | undefined, string>): React.ReactElement => {
    const color = value ?? '#000000';
    return <ui.Box>
        <ui.HStack>
            <ui.Box
                width="100px"
                backgroundColor={color}
                py={2}
                borderRadius={4}
            >
                <ui.Text
                    color={fontColor(color)}
                    fontWeight="bold"
                    fontSize="md"
                    textAlign="center"
                >
                    {color}
                </ui.Text>
            </ui.Box>
            <ui.Spacer />
            <ui.IconButton
                icon={<icons.RepeatIcon />}
                aria-label="reload"
                onClick={(e) => onChange(e, generateRandomColor())}
            />
        </ui.HStack>
    </ui.Box>
};



export const ImageDialog = ({
    src,
    title,
    ...props
}: {
    src: string;
    title?: string | null;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    return <ui.Modal
        {...props}
        size="lg"
        isCentered
    >
        <ui.ModalOverlay />
        <ui.ModalContent>
            {title && <ui.ModalHeader textAlign="center">{title}</ui.ModalHeader>}
            {title && <ui.ModalCloseButton />}
            <ui.ModalBody>
                <ui.Image
                    flexGrow={0}
                    flexShrink={0}
                    maxWidth="100%"
                    maxHeight="100%"
                    src={src}
                    cursor="pointer"
                    onClick={props.onClose}
                />
            </ui.ModalBody>
        </ui.ModalContent>
    </ui.Modal>;
};



export const S3ImageDialog = ({
    base,
    processor,
    ...props
}: {
    base?: string | null;
    processor?: PROCESSOR_NAMES;
} & Excluded<Props<typeof ImageDialog>, 'src'>): React.ReactElement => {
    return <ImageDialog
        src={base ? imgurl(base, processor) : placeholder}
        {...props}
    />;
};
