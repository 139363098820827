import {React, ui} from 'lib';

import {useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, ImageInput, useForm, input, generateImage} from 'shared';
import {Team} from 'types/stock/api/data.gen';


export const TeamCreationModal = ({
    onComplete,
    ...props
}: {
    onComplete(team: Team): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'create_team';
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>(() => ({
        icon: generateImage(),
        name: '',
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">チームの追加</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名前"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：家族"
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="full"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                >
                    作成
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
