import {React, Apollo} from 'lib';
import {setContext} from '@apollo/client/link/context';

import {useSessionKey} from './api';


export const useClient = (): Apollo.ApolloClient<Apollo.NormalizedCacheObject> => {
    const sessionKey = useSessionKey();

    return React.useMemo(() => {
        const httpLink = Apollo.createHttpLink({
            uri: process.env.REACT_APP_API_BASE_URL + '/graphql',
        });

        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    'X-Session-Key': sessionKey,
                }
            }
        });

        return new Apollo.ApolloClient({
            link: authLink.concat(httpLink),
            cache: new Apollo.InMemoryCache()
        });
    }, [sessionKey]);
};


export const ProvideClient = ({children}: {children: React.ReactNode}) => <Apollo.ApolloProvider
    client={useClient()}
    children={children}
/>;
