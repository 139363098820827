import {React, ui} from 'lib';
import Quagga, {QuaggaJSResultObject, MediaTrackConstraintsWithDeprecated, QuaggaJSConfigObject, QuaggaJSReaderConfig} from '@ericblade/quagga2';

import {InputProps, useForm, FormItem, input} from 'shared';


export const BarcodeListInput = ({
    value,
    onChange,
}: InputProps<string[], string[], null>): React.ReactElement => {
    const disclosure = ui.useDisclosure()
    const toast = ui.useToast()
    const [editing, setEditing] = React.useState<string>();
    const editingDisclosure = ui.useDisclosure({
        isOpen: !!editing,
        onClose: () => setEditing(undefined),
    });
    return <ui.Box>
        <ui.HStack>
            <ui.Box flexBasis="100%">
                {value.length === 0 && <ui.Text size="sm" color="gray.500">
                    登録されていません
                </ui.Text>}

                {value.length > 0 && <ui.Flex flexWrap="wrap">
                    {value.map((v, i) => <ui.Box mr={1} mt={1} key={i}>
                        <ui.Button size="xs" onClick={() => setEditing(v)}>
                            {v}
                        </ui.Button>
                    </ui.Box>)}
                </ui.Flex>}
            </ui.Box>

            <ui.Box flexShrink={0} flexGrow={0}>
                <ui.Button
                    onClick={disclosure.onOpen}
                    size="sm"
                >
                    追加
                </ui.Button>
            </ui.Box>
        </ui.HStack>

        <ScannerDialog
            {...disclosure}
            onDetected={(result) => {
                if (value.includes(result)) {
                    toast({
                        title: 'このバーコードは既に設定されています',
                        description: result,
                        status: 'info',
                        duration: 3000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'バーコードを読み込みました',
                        description: result,
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                    onChange(null, value.concat([result]));
                    disclosure.onClose();
                }
            }}
        />

        <BarcodeEditDialog
            barcode={editing}
            onDone={(barcode) => {
                onChange(null, value.map(v => v === editing ? barcode : v));
                editingDisclosure.onClose();
            }}
            onRemoved={() => {
                onChange(null, value.filter(v => v !== editing));
                editingDisclosure.onClose();
            }}
            {...editingDisclosure}
        />
    </ui.Box>
};


const BarcodeEditDialog = ({barcode, onDone, onRemoved, ...props}: {
    barcode?: string;
    onDone(barcode: string): void;
    onRemoved(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>) => {
    const {binder, handleSubmit, reset} = useForm<{barcode?: string}>({
        barcode,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(({barcode}) => onDone(barcode ?? ''));

    return <ui.Modal
        {...props}
        size="sm"
        isCentered
    >
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">コードの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <FormItem label="コード">
                    <input.Input
                        {...binder.mapInputProps('barcode')}
                    />
                </FormItem>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button
                    colorScheme="red"
                    onClick={() => onRemoved()}
                >
                    削除
                </ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={submit}
                    isDisabled={!binder.value.barcode}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};


export const ScannerDialog = ({isOpen, onClose, onDetected}: {
    isOpen: boolean;
    onClose(): void;
    onDetected(code: string): void;
}): React.ReactElement | null => {
    const scannerRef = React.useRef<HTMLDivElement>(null);
    const callbackRef = React.useRef(onDetected);
    callbackRef.current = onDetected;

    const detected = React.useCallback((r) => {
        callbackRef.current?.(r);
    }, [callbackRef]);

    if (!isOpen) {
        return null;
    }

    return <ui.Portal>
        <ui.Box position="fixed" top={0} left={0} right={0} bottom={0} width="100%" height="100%" maxWidth="640px" maxHeight="640px" margin="auto" background="black" zIndex={1400}>
            <ui.Box
                ref={scannerRef}
                maxWidth="100%"
                maxHeight="100%"
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                m="auto"
            >
                <canvas
                    className="drawingBuffer"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                    }}
                />
                <video
                    className="drawingBuffer"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                    }}
                />

                {isOpen && <Scanner
                    scannerRef={scannerRef}
                    onDetected={detected}
                />}

                <ui.CloseButton
                    position="absolute"
                    top={0}
                    right={0}
                    onClick={onClose}
                    zIndex={1}
                    size="lg"
                    color="white"
                />
            </ui.Box>
        </ui.Box>
    </ui.Portal>
}

const defaultConstraints = {
    width: 1280,
    height: 1280,
    focusMode: 'continuous',
};

const defaultLocatorSettings = {
    patchSize: 'medium',
    halfSample: true,
    debug: {
        showCanvas: false,
    },
};

const defaultDecoders = ['ean_reader', 'ean_8_reader'];


export const Scanner = ({
    onDetected,
    scannerRef,
    onScannerReady,
    constraints = defaultConstraints,
    locator = defaultLocatorSettings,
    numOfWorkers = navigator.hardwareConcurrency || 0,
    decoders = defaultDecoders,
    locate = false,
}: {
    onDetected(code: string): void;
    scannerRef: React.RefObject<HTMLDivElement | null>;
    onScannerReady?(): void;
    constraints?: MediaTrackConstraintsWithDeprecated;
    locator?: QuaggaJSConfigObject['locator'];
    numOfWorkers?: number;
    decoders?: (QuaggaJSReaderConfig | string)[];
    locate?: boolean;
}) => {
    const detected = React.useRef<{[key: string]: number}>({});

    const errorCheck = React.useCallback((result: QuaggaJSResultObject) => {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        const code = result.codeResult.code;
        if (err < 0.5 && code) {
            if (code in detected.current) {
                detected.current[code] += 1;
                if (detected.current[code] >= 3) {
                    detected.current = {};
                    onDetected(code);
                }
            } else {
                detected.current[code] = 1;
            }
        }
    }, [onDetected]);

    React.useEffect(() => {
        Quagga.init({
            inputStream: {
                type: 'LiveStream',
                constraints,
                target: scannerRef.current ?? undefined,
            },
            locator,
            numOfWorkers,
            decoder: { readers: decoders },
            locate,
        }, (err) => {
            if (err) {
                return console.log('Error starting Quagga:', err);
            }
            if (scannerRef?.current) {
                Quagga.start();
                if (onScannerReady) {
                    onScannerReady();
                }
            }
        });
        Quagga.onDetected(errorCheck);
        return () => {
            Quagga.offDetected(errorCheck);
            Quagga.stop();
        };
    }, [onDetected, onScannerReady, scannerRef, errorCheck, constraints, locator, numOfWorkers, decoders, locate]);
    return null;
}



const getMedianOfCodeErrors = (decodedCodes: QuaggaJSResultObject['codeResult']['decodedCodes']): number => {
    const errors = decodedCodes.filter(x => x.error !== undefined).map(x => x.error as number);
    errors.sort((a, b) => a - b);
    const half = Math.floor(errors.length / 2);
    if (errors.length % 2 === 1) {
        return errors[half];
    }
    return (errors[half - 1] + errors[half]) / 2;
};
