import {React, ui, icons, Router, urls} from 'lib';

import {LoadingScreen, useRemoteData, useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiErrorDialog, ApiCompletionDialog, AlertDialog, LayoutItem, ImageInput, S3ImageWithPreview, imgurl, RouterTextLink, useForm, input, Section} from 'shared';
import {StockList} from 'components/stock/list_for_storage';
import {StockSettingModal} from 'components/stock/setting';
import {ItemCreationModal} from 'components/item/creation';
import {withFrame} from 'components/frame';
import {WorkspaceProvider} from 'components/workspace/context';
import {Storage} from 'types/stock/api/data.gen';


export const StorageDetail = withFrame(({storageId}: {
    storageId: string;
}): React.ReactElement => {
    const history = Router.useHistory();
    const api = useRemoteData({
        path: 'get_storage',
        request: {storage_id: storageId},
        reloadWhenFailed: true,
        onClientError: () => history.replace('/'),
    });
    const editionDisclosure = ui.useDisclosure();
    const stockDisclosure = ui.useDisclosure();
    const stockListRef = React.useRef<Ref<typeof StockList>>(null);

    const itemCreationDisclosure = ui.useDisclosure();

    if (api.state !== 'success') {
        return <LoadingScreen />
    }

    const {storage, workspace} = api.data;

    return <WorkspaceProvider workspace={workspace}>
        <Section>
            <LayoutItem>
                <ui.HStack>
                    <ui.Text>
                        <RouterTextLink to={urls.home()}>HOME</RouterTextLink>
                    </ui.Text>

                    <ui.Text>
                        {'>'} <RouterTextLink to={urls.workspace(workspace.id)}>{workspace.name}</RouterTextLink>
                    </ui.Text>
                </ui.HStack>
            </LayoutItem>

            <LayoutItem.Group space={4}>
                <LayoutItem>
                    <ui.Box>
                        <ui.HStack width="100%">
                            <S3ImageWithPreview
                                base={storage.icon.base_url}
                                processor="cov128"
                                borderRadius="full"
                                width="64px"
                                height="64px"
                                mr={2}
                                previewTitle={storage.name}
                            />

                            <ui.Heading size="xl">
                                {storage.name}
                            </ui.Heading>

                            <ui.Spacer />

                            <ui.IconButton
                                onClick={editionDisclosure.onOpen}
                                icon={<icons.EditIcon />}
                                aria-label="edit-storage"
                            />
                        </ui.HStack>
                    </ui.Box>
                </LayoutItem>

                {!!storage.note && <LayoutItem>
                    <ui.Text whiteSpace="pre-line" fontSize="sm">
                        {storage.note}
                    </ui.Text>
                </LayoutItem>}

                <LayoutItem>
                    <ui.HStack>
                        <ui.Button
                            colorScheme="blue"
                            onClick={stockDisclosure.onOpen}
                            leftIcon={<icons.EditIcon />}
                        >
                            在庫の設定
                        </ui.Button>

                        <ui.Button
                            onClick={itemCreationDisclosure.onOpen}
                            leftIcon={<icons.AddIcon />}
                        >
                            アイテムを登録
                        </ui.Button>
                    </ui.HStack>
                </LayoutItem>
            </LayoutItem.Group>
        </Section>

        <Section title="在庫一覧">
            <StockList
                ref={stockListRef}
                storage={storage}
                empty={`${storage.name}には在庫が登録されていません`}
            />
        </Section>

        <StockSettingModal
            {...stockDisclosure}
            storage={storage}
            onComplete={() => {
                stockListRef.current?.reload();
                stockDisclosure.onClose();
            }}
            onCompleteAndThen={() => {
                stockListRef.current?.reload();
            }}
        />

        <ItemCreationModal
            {...itemCreationDisclosure}
            workspace={workspace}
            defaultStorages={[storage]}
            defaultQuantity={() => 1}
            onComplete={() => {
                stockListRef.current?.reload();
                itemCreationDisclosure.onClose();
            }}
            onCompleteAndThen={() => {
                stockListRef.current?.reload();
            }}
        />

        <StorageEditionModal
            {...editionDisclosure}
            storage={storage}
            onComplete={(storage) => {
                api.setData({...api.data, storage});
                editionDisclosure.onClose();
            }}
            onDeleted={() => history.replace(urls.workspace(workspace.id))}
        />
    </WorkspaceProvider>;
});



export const StorageEditionModal = ({
    storage,
    onComplete,
    onDeleted,
    ...props
}: {
    storage: Storage;
    onComplete(storage: Storage): void;
    onDeleted(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_storage';
    const api = useWriterApi(path);
    const deleteApi = useWriterApi('delete_storage');

    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        storage_id: storage.id,
        icon: imgurl(storage.icon.base_url, 'cov128'),
        name: storage.name,
        note: storage.note,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">保管場所の編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名前"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：リビング、寝室、給湯室"
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="full"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>

                    <FormItem
                        label="メモ"
                        keyPath="note"
                        error={api.error?.data}
                    >
                        <input.Textarea
                            {...binder.mapInputProps('note')}
                            placeholder=""
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />

        <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={() => deleteApi.call({storage_id: storage.id}).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text color="gray.700" fontSize="sm">
                    在庫がある保管場所は削除できません。削除するには事前に全ての在庫を0にするか在庫を削除してください。
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text color="red">
                    削除した場合、{storage.name}に含まれるすべての在庫の更新履歴も削除されます。
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    この操作は取り消せません。<br />
                    本当に削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>
        <ApiSpinnerDialog api={deleteApi} />
        <ApiErrorDialog api={deleteApi} onOk={deleteApi.reset} />
        <ApiCompletionDialog
            message="データを削除しました"
            api={deleteApi}
            onOk={onDeleted}
        />
    </ui.Modal>;
};
