import {React, ui, Router} from 'lib';

import {LoadingScreen, SimpleCenteredContent, FormItem, LayoutItem, ErrorText, Req, ImageInput, useForm, input, generateInitialImage} from 'shared';
import {useSession, useWriterApi, useRemoteData} from 'shared/api';
import {Frame} from 'components/frame';
import {TermsOfServiceModal} from 'terms_of_service';
import {PrivacyPolicyModal} from 'privacy_policy';


export const Signup = ({token}: {
    token?: string;
}): React.ReactElement => {
    return <Frame loginExempt={true}>
        {token ? <WithToken token={token} /> : <SendMail />}
    </Frame>
}


const SendMail = (): React.ReactElement => {
    const session = useSession();
    const [email, setEmail] = React.useState('')

    const api = useWriterApi('send_email_for_signup');
    const submit = () => api.call({email});

    return <SimpleCenteredContent title="会員登録">
        {api.state !== 'success' && <ui.VStack width="300px" alignItems="start">
            <FormItem
                label="メールアドレス"
                keyPath="email"
                error={api.error?.data}
            >
                <ui.Input
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </FormItem>

            <ErrorText>
                {api.error?.message}
            </ErrorText>

            <ui.Button onClick={submit} width="100%" isDisabled={api.loading || session.loading}>
                確認メールを送信
            </ui.Button>
        </ui.VStack>}

        {api.state === 'success' && <ui.VStack width="300px">
            <ui.Text>
                ご利用ありがとうございます。
            </ui.Text>
            <ui.Text>
                下記メールアドレスに登録用のURLを送信しました。
            </ui.Text>

            <ui.Box mt={5}>
                <ui.Code fontSize="large" mt={5}>
                    {api.data}
                </ui.Code>
            </ui.Box>
        </ui.VStack>}
    </SimpleCenteredContent>
};


const WithToken = ({token}: {token: string}) => {
    const {loading, data: email} = useRemoteData({
        path: 'retrieve_email_from_token',
        request: {token, check_used: true},
    });
    const history = Router.useHistory();

    if (loading) {
        return <LoadingScreen />
    }

    if (!email) {
        return <SimpleCenteredContent title="会員登録">
            <ui.VStack spacing={5}>
                <ui.Text color="red.500">
                    URLが間違っているか有効期限切れです
                </ui.Text>

                <ui.Button onClick={() => history.replace('/')}>
                    トップページに戻る
                </ui.Button>
            </ui.VStack>
        </SimpleCenteredContent>;
    }

    return <RegistrationForm token={token} email={email} />;
};

const RegistrationForm = ({token, email}: {
    token: string;
    email: string;
}) => {
    const session = useSession();
    const history = Router.useHistory();

    const path = 'register_user';
    const api = useWriterApi(path);

    const {binder, handleSubmit} = useForm<Req<typeof path>>(() => {
        const name = email.split('@')[0]
            .replace(/[-.+_]/g, ' ')
            .replace(/ {2,}/g, ' ');
        return {
            token,
            icon: generateInitialImage(name),
            name,
            password: '',
        }
    });

    const termsDisclosure = ui.useDisclosure();
    const privacyPolicyDisclosure = ui.useDisclosure();
    const submit = handleSubmit(async (data) => {
        if (await api.call(data)) {
            session.reload();
        }
    });

    if (api.state === 'success') {
        return <SimpleCenteredContent title="会員登録">
            <ui.VStack spacing={5}>
                <ui.Box>
                    <ui.Text>
                        会員登録が完了しました。
                    </ui.Text>
                </ui.Box>

                <ui.Box>
                    <ui.Button onClick={() => history.replace('/')}>
                        続ける
                    </ui.Button>
                </ui.Box>
            </ui.VStack>
        </SimpleCenteredContent>
    }

    return <SimpleCenteredContent title="会員登録">
        <ui.VStack width="100%" spacing={5} alignItems="start">
            <FormItem label="メールアドレス">
                <ui.Input value={email} readOnly={true} />
            </FormItem>

            <FormItem
                label="パスワード"
                keyPath="password"
                error={api.error?.data}
            >
                <input.PasswordInput
                    {...binder.mapInputProps('password')}
                />
            </FormItem>

            <FormItem
                label="名前"
                keyPath="name"
                error={api.error?.data}
            >
                <input.Input {...binder.mapInputProps('name')} />
            </FormItem>

            <FormItem
                label="アイコン"
                keyPath="icon"
                error={api.error?.data}
            >
                <ImageInput
                    {...binder.mapInputProps('icon')}
                    width="64px"
                    height="64px"
                    borderRadius="full"
                    nameForInitial={binder.value.name}
                />
            </FormItem>

            <LayoutItem>
                <ui.Text fontSize="sm" color="gray.600">
                    登録前に必ず
                    <ui.Link color="blue.500" onClick={termsDisclosure.onOpen}>利用規約</ui.Link>
                    と
                    <ui.Link color="blue.500" onClick={privacyPolicyDisclosure.onOpen}>プライバシーポリシー</ui.Link>
                    をご確認ください
                </ui.Text>
            </LayoutItem>

            <ui.Button onClick={submit} width="100%" isDisabled={api.loading || session.loading}>
                利用規約に同意して登録する
            </ui.Button>
        </ui.VStack>

        <TermsOfServiceModal {...termsDisclosure} />
        <PrivacyPolicyModal {...privacyPolicyDisclosure} />
    </SimpleCenteredContent>;
};
