import {React, ui, icons} from 'lib';

import {Item, Workspace} from 'types/stock/api/data.gen';
import {Loading, Req, useReaderApi, InputProps, useForm, input, LayoutItem, S3Image} from 'shared';
import {GenericList, GenericListPropsButItems} from 'components/generic/list';
import {ScannerDialog} from 'components/barcode';
import {useLabelData, Labels, LabelsDialog} from 'components/label';
import {ItemCreationModal} from 'components/item/creation';


type ItemListProps = {
    workspace: Workspace;
    onDetectBarcode?(barcode: string): void;
    onCreated?(item: Item): void;
} & GenericListPropsButItems<Item>;

export const ItemList = React.forwardRef<{
    reload(): void;
}, ItemListProps>(({
    workspace,
    onDetectBarcode,
    onCreated,
    ...other
}, ref): React.ReactElement => {
    const [items, setItems] = React.useState([] as Item[]);
    const [next, setNext] = React.useState<string>();
    const [filter, setFilter] = React.useState<ItemFilter>({});
    const hasFilter = Boolean(filter?.keyword || filter?.barcode || filter?.label_ids?.length);
    const api = useReaderApi('list_items');

    const callNumber = React.useRef(0);

    const request = React.useRef<Req<'list_items'>>({});
    request.current = {workspace_ids: [workspace.id], next, ...filter};

    const call = React.useRef((reset: boolean) => {
        callNumber.current += 1;
        const num = callNumber.current;
        setTimeout(() => {
            if (num !== callNumber.current) {
                return;
            }
            api.call(request.current).then((data) => {
                if (api.isMounted() && num === callNumber.current) {
                    setItems(items => (reset ? [] : items).concat(data.items));
                }
            });
        }, 1);
    });

    React.useEffect(() => {
        call.current(false);
    }, [next]);

    React.useEffect(() => {
        call.current(true);
    }, [filter?.keyword, filter?.barcode, filter?.label_ids]);

    React.useImperativeHandle(ref, () => ({
        reload: () => {
            call.current(true);
        },
    }));

    const creationDisclosure = ui.useDisclosure();

    const {empty, ...props} = other;

    return <LayoutItem.Group>
        {(hasFilter || (api.state === 'success' && api.data.items.length > 0)) && <LayoutItem>
            <ItemFilterInput
                value={filter}
                onChange={(_, v) => {
                    setNext(undefined);
                    if (v.barcode && filter?.barcode !== v.barcode) {
                        onDetectBarcode?.(v.barcode);
                    }
                    setFilter(v);
                }}
            />
        </LayoutItem>}

        <LayoutItem>
            <GenericList
                {...props}
                items={items}
                empty={api.state === 'success' && empty ? (hasFilter ? '条件に一致するアイテムがありません' : empty) : undefined}
                spacing="4px"
                width={{
                    base: 'calc(25% - 4px)',
                    sm: 'calc(20% - 4px)',
                    md: 'calc(16.66% - 4px)',
                }}
                as={ItemComponent}
            />
        </LayoutItem>

        {onCreated && filter?.barcode && <LayoutItem>
            <ui.Text>
                <ui.Link color="blue.500" onClick={creationDisclosure.onOpen}>
                    バーコードを指定したアイテムを登録
                </ui.Link>
            </ui.Text>
        </LayoutItem>}

        {api.state !== 'success' && <LayoutItem>
            <Loading />
        </LayoutItem>}

        {api.data?.next && <LayoutItem>
            <ui.Text>
                <ui.Link
                    color="blue.500"
                    onClick={() => setNext(api.data.next ?? undefined)}
                >
                    続きを読み込む
                </ui.Link>
            </ui.Text>
        </LayoutItem>}

        {onCreated && <ItemCreationModal
            {...creationDisclosure}
            initial={{
                barcodes: filter?.barcode ? [filter.barcode] : [],
            }}
            workspace={workspace}
            onComplete={(item) => {
                onCreated(item);
                creationDisclosure.onClose();
            }}
        />}
    </LayoutItem.Group>
});



const ItemComponent = ({item, isSelected}: {
    item: Item;
    isSelected: boolean;
}) => {
    return <ui.Box
        border="1px"
        borderColor={isSelected ? 'gray.600' : 'gray.300'}
        bg={isSelected ? 'gray.600' : undefined}
        borderRadius="4px"
        overflow="hidden"
        width="100%"
        position="relative"
    >
        <ui.Box pt="100%" position="relative">
            <S3Image
                position="absolute"
                top={0}
                left={0}
                base={item.icon.base_url}
                processor="cov128"
                width="100%"
                height="100%"
            />
        </ui.Box>

        <ui.Text
            bg="rgba(0, 0, 0, 0.3)"
            color="white"
            display="inline-block"
            position="absolute"
            height="22px"
            bottom="20px"
            left={0}
            px={1}
            fontSize="sm"
        >
            {item.total_quantity}
        </ui.Text>
        <ui.Text
            px={0.5}
            textAlign="center"
            fontWeight="bold"
            fontSize="xs"
            color={isSelected ? 'white' : undefined}
            isTruncated={true}
            width="100%"
        >
            {item.name}
        </ui.Text>
    </ui.Box>;
};


type ItemFilter = {
    keyword?: string;
    barcode?: string;
    label_ids?: string[];
};

const ItemFilterInput = ({
    value,
    onChange,
}: InputProps<ItemFilter | null | undefined, ItemFilter>) => {
    const {binder} = useForm(value ?? {});
    const barcodeDisclosure = ui.useDisclosure()
    const labelDisclosure = ui.useDisclosure()
    const toast = ui.useToast()
    const hasBarcode = !!binder.value.barcode;

    const labelData = useLabelData();
    const labelIds = binder.value.label_ids ?? [];
    const labels = labelData.data?.filter(l => labelIds.includes(l.id)) ?? [];

    return <ui.Box>
        {hasBarcode && <ui.InputGroup>
            <ui.Input
                pr="2.5rem"
                value={binder.value.barcode}
                readOnly={true}
            />
            <ui.InputRightElement width="2.5rem">
                <ui.Button
                    h="1.75rem"
                    size="xs"
                    onClick={(e) => {
                        onChange(e, binder.assign({barcode: undefined}));
                    }}
                >
                    <icons.CloseIcon />
                </ui.Button>
            </ui.InputRightElement>
        </ui.InputGroup>}

        {!hasBarcode && <>
            <ui.InputGroup>
                <ui.InputLeftElement
                    pointerEvents="none"
                    children={<icons.SearchIcon color="gray.300" />}
                />
                <input.Input
                    type="search"
                    pr="5.5rem"
                    {...binder.mapInputProps('keyword', '')}
                    onEnter={e => onChange(e, binder.value)}
                    onBlur={e => onChange(e, binder.value)}
                />
                <ui.InputRightElement width="9rem">
                    <ui.Button h="1.75rem" mr={1} size="xs" onClick={labelDisclosure.onOpen}>
                        ラベル
                    </ui.Button>

                    <ui.Button h="1.75rem" size="xs" onClick={barcodeDisclosure.onOpen}>
                        バーコード
                    </ui.Button>
                </ui.InputRightElement>
            </ui.InputGroup>

            {labels.length > 0 && <ui.Box mt={2}>
                <Labels labels={labels} />
            </ui.Box>}
        </>}

        <LabelsDialog
            value={labels}
            onChange={(e, v) => {
                onChange(e, binder.assign({
                    label_ids: v.map(l => l.id),
                }));
                labelDisclosure.onClose();
            }}
            {...labelDisclosure}
        />

        <ScannerDialog
            {...barcodeDisclosure}
            onDetected={(result) => {
                toast({
                    title: 'バーコードを読み込みました',
                    description: result,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
                binder.bind('barcode').change(result);
                onChange(null, {barcode: result});
                barcodeDisclosure.onClose();
            }}
        />
    </ui.Box>;
};
