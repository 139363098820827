import {React, ui, icons} from 'lib';

import {Label} from 'types/stock/api/data.gen';
import {InputProps, Spinner, LayoutItem, useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, AlertDialog, generateRandomColor, ColorInput, fontColor, useForm, input} from 'shared';
import {useWorkspace, useLabelData} from 'components/workspace/context';

export {useLabelData} from 'components/workspace/context';


export const LabelComponent = ({label, ...props}: {
    label: Label;
} & Pick<ui.TagProps, 'size' | 'onClick'>): React.ReactElement => {
    return <ui.Tag
        color={fontColor(label.color)}
        backgroundColor={label.color}
        whiteSpace="nowrap"
        {...props}
    >
        {label.name}
    </ui.Tag>
};


export const Labels = ({labels, size}: {
    labels: Label[];
    size?: ui.TagProps['size'];
}): React.ReactElement => {
    return <ui.Box overflow="hidden">
        <ui.Flex
            flexWrap="wrap"
            mt={-0.5}
            ml={-1}
        >
            {labels.map((label) => <ui.Box mt={0.5} ml={1} key={label.id}>
                <LabelComponent label={label} size={size} />
            </ui.Box>)}
        </ui.Flex>
    </ui.Box>
};


export const LabelListInput = ({
    value: _value,
    onChange,
}: InputProps<string[] | null | undefined, string[]>): React.ReactElement => {
    const value = _value ?? [];
    const disclosure = ui.useDisclosure()
    const remoteData = useLabelData();
    const mapping = React.useMemo(() => {
        return remoteData.data?.reduce((acc, label) => {
            acc[label.id] = label;
            return acc;
        }, {} as Keyed<Label>) ?? {};
    }, [remoteData.data]);

    if (!remoteData.data) {
        return <Spinner />
    }

    const labels = value.filter(n => n in mapping).map(n => mapping[n]);
    return <ui.Box>
        <ui.HStack>
            <ui.Box flexBasis="100%">
                {value.length === 0 && <ui.Text size="sm" color="gray.500">
                    登録されていません
                </ui.Text>}

                {value.length > 0 && <Labels labels={labels} />}
            </ui.Box>

            <ui.Box flexShrink={0} flexGrow={0}>
                <ui.Button
                    onClick={disclosure.onOpen}
                    size="sm"
                >
                    設定
                </ui.Button>
            </ui.Box>
        </ui.HStack>

        <LabelsDialog
            value={labels}
            onChange={(e, v) => {
                onChange(e, v.map(l => l.id));
                disclosure.onClose();
            }}
            {...disclosure}
        />
    </ui.Box>
};


export const LabelsDialog = ({
    value,
    onChange,
    ...props
}: InputProps<Label[]> & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const labelsData = useLabelData();
    const [ids, setIds] = React.useState([] as string[]);
    const disclosure = ui.useDisclosure();

    React.useEffect(() => {
        if (!props.isOpen) {
            setIds(value.map(l => l.id));
        }
    }, [value, props.isOpen]);

    const [editing, setEditing] = React.useState<Label>();
    const editionDisclosure = ui.useDisclosure({
        isOpen: !!editing,
        onClose: () => setEditing(undefined),
    });

    const toggle = (label: Label) => {
        setIds(ids.includes(label.id)
            ? ids.filter(id => id !== label.id)
            : ids.concat([label.id]));
    };

    const labels = labelsData.data ?? [];

    return <ui.Modal {...props} size="sm">
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ラベルを選択</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <LayoutItem>
                    <ui.Button
                        colorScheme="green"
                        onClick={disclosure.onOpen}
                        leftIcon={<icons.AddIcon />}
                    >
                        新規
                    </ui.Button>
                </LayoutItem>

                <LayoutItem.Group space={2}>
                    {labels.map((label) => <LayoutItem key={label.id}>
                        <ui.HStack>
                            <ui.Checkbox
                                isChecked={ids.includes(label.id)}
                                onChange={() => toggle(label)}
                                size="md"
                            />
                            <LabelComponent
                                label={label}
                                size="md"
                                onClick={() => toggle(label)}
                            />

                            <ui.Spacer />

                            <ui.IconButton
                                onClick={() => setEditing(label)}
                                icon={<icons.EditIcon />}
                                aria-label="edit-place"
                                size="sm"
                            />
                        </ui.HStack>
                    </LayoutItem>)}
                </LayoutItem.Group>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={(e) => onChange(e, labels.filter(l => ids.includes(l.id)))}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <LabelCreationModal
            {...disclosure}
            onComplete={(item) => {
                labelsData.reload();
                toggle(item);
                disclosure.onClose();
            }}
        />

        {editing && <LabelEditionModal
            {...editionDisclosure}
            label={editing}
            onComplete={() => {
                labelsData.reload();
                editionDisclosure.onClose();
            }}
            onDeleted={() => {
                labelsData.reload();
                editionDisclosure.onClose();
            }}
        />}
    </ui.Modal>;
};


export const LabelCreationModal = ({
    onComplete,
    onCompleteAndThen,
    ...props
}: {
    onComplete(label: Label): void;
    onCompleteAndThen?(label: Label): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const workspace = useWorkspace();
    const path = 'create_label';
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        workspace_id: workspace.id,
        name: '',
        color: generateRandomColor(),
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(api.call);

    return <ui.Modal size="sm" {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ラベルを登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名称"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：食料品、消耗品"
                        />
                    </FormItem>

                    <FormItem
                        label="カラー"
                        keyPath="color"
                        error={api.error?.data}
                    >
                        <ColorInput
                            {...binder.mapInputProps('color')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    登録
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
            okAndThen="続けて登録する"
            onOkAndThen={onCompleteAndThen ? (data) => {
                onCompleteAndThen?.(data);
                reset();
                api.reset();
            } : undefined}
        />
    </ui.Modal>;
};



export const LabelEditionModal = ({
    label,
    onComplete,
    onDeleted,
    ...props
}: {
    label: Label;
    onComplete(label: Label): void;
    onDeleted(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_label';
    const api = useWriterApi(path);
    const deleteApi = useWriterApi('delete_label');

    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>({
        label_id: label.id,
        name: label.name,
        color: label.color,
    });

    React.useEffect(() => {
        reset();
    }, [reset, label]);

    const submit = handleSubmit(data => api.call(data));
    const confirmDisclosure = ui.useDisclosure();

    return <ui.Modal size="sm" {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">ラベルの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名称"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：食料品、消耗品"
                        />
                    </FormItem>

                    <FormItem
                        label="カラー"
                        keyPath="color"
                        error={api.error?.data}
                    >
                        <ColorInput
                            {...binder.mapInputProps('color')}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button colorScheme="red" onClick={confirmDisclosure.onOpen}>削除</ui.Button>
                <ui.Spacer />
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
        />

        <AlertDialog
            {...confirmDisclosure}
            title="削除の確認"
            confirm="削除する"
            onConfirm={() => deleteApi.call({label_id: label.id}).then(confirmDisclosure.onClose)}
            onCancel={confirmDisclosure.onClose}
        >
            <LayoutItem>
                <ui.Text color="red">
                    すべてのアイテムから {label.name} が削除されます
                </ui.Text>
            </LayoutItem>
            <LayoutItem>
                <ui.Text>
                    この操作は取り消せません。<br />
                    本当に削除してよろしいですか？
                </ui.Text>
            </LayoutItem>
        </AlertDialog>
        <ApiSpinnerDialog api={deleteApi} />
        <ApiCompletionDialog
            message="ラベルを削除しました"
            api={deleteApi}
            onOk={onDeleted}
        />
    </ui.Modal>;
};
