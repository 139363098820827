import {React} from 'lib';

import {DataBinder, useDataBinder} from './binder';


type Delayed<T, D extends keyof T> = {
    [K in keyof T]: K extends D ? T[K] | undefined : T[K];
};

type Initial<T, D extends keyof T> = Delayed<T, D> | {(): Delayed<T, D>};

type Submit<T> = {(value: T): void} | {(value: T): Promise<void>};

export type Form<T, D extends keyof T> = {
    value: Delayed<T, D>;
    reset(value?: Delayed<T, D>): void;
    binder: DataBinder<Delayed<T, D>>;
    handleSubmit(submit: Submit<T>): {(e?: React.BaseSyntheticEvent): void};
};


export const useForm = <T, D extends keyof T = never>(initial: Initial<T, D>): Form<T, D> => {
    const [state, setState] = React.useState(initial);
    const binder = useDataBinder(state, setState);

    const initialRef = React.useRef(initial);
    initialRef.current = initial;

    const reset = React.useCallback((value?: Delayed<T, D>) => {
        setState(value === undefined ? initialRef.current : value);
    }, []);

    return React.useMemo(() => {
        return {
            value: state,
            reset,
            binder,
            handleSubmit: (submit: Submit<T>) => (e?: React.BaseSyntheticEvent) => {
                e?.preventDefault();
                submit(state as T);
            },
        };
    }, [state, reset, binder]);
};
