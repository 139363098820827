import {React, ui} from 'lib';

import {InputProps} from './binder';


type AsInputProps<C, T, CT extends T = T> = C extends React.JSXElementConstructor<infer P>
    ? P extends {value?: infer V; onChange?: {(e: infer E): void}}
        ? V extends T
            ? InputProps<T, CT, E> & Excluded<RemoveIndex<P>, 'value' | 'onChange'>
            : never
        : never
    : never;


type AsInputCustomProps<C, T, CT extends T, E> = C extends React.JSXElementConstructor<infer P>
    ? P extends {value?: infer V; onChange?: unknown}
        ? V extends T
            ? InputProps<T, CT, E> & Excluded<RemoveIndex<P>, 'value' | 'onChange'>
            : never
        : never
    : never;



export const Input = React.memo(React.forwardRef<Ref<typeof ui.Input>, AsInputProps<typeof ui.Input, string | undefined, string> & {
    onEnter?(e: React.KeyboardEvent<HTMLInputElement>): void;
}>(({
    onChange,
    onKeyPress,
    onEnter,
    ...props
}, ref) => {
    return <ui.Input
        onChange={React.useCallback((e) => onChange(e, e.target.value), [onChange])}
        onKeyPress={(e) => {
            if (onEnter && e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                onEnter(e);
            }

            return onKeyPress?.(e);
        }}
        ref={ref}
        {...props}
    />
}));
// https://github.com/chakra-ui/chakra-ui/issues/2269
Object.assign(Input, {id: 'Input'});


export const Textarea = React.memo(({onChange, ...props}: AsInputProps<typeof ui.Textarea, string>) => {
    return <ui.Textarea
        onChange={React.useCallback((e) => onChange(e, e.target.value), [onChange])}
        {...props}
    />
});


export const NumberInput = React.memo(({value, onChange, ...props}: AsInputCustomProps<typeof ui.NumberInput, number | null | undefined, number, null>) => {
    const [internal, setInternal] = React.useState(String(value ?? ''))

    React.useEffect(() => {
        setInternal(String(value ?? ''));
    }, [value]);


    return <ui.NumberInput
        value={internal}
        onChange={React.useCallback((v, nv: number) => {
            setInternal(v);
            if (!isNaN(nv)) {
                onChange(null, nv);
            }
        }, [onChange])}
        {...props}
    />
});


export const PasswordInput = React.memo(React.forwardRef<Ref<typeof ui.Input>, AsInputProps<typeof ui.Input, string | undefined, string>>(({onChange, ...props}, ref) => {
    const [showPassword, setShowPassword] = React.useState(false)

    return <ui.InputGroup>
        <ui.Input
            onChange={React.useCallback((e) => onChange(e, e.target.value), [onChange])}
            pr="4.5rem"
            type={showPassword ? 'text' : 'password'}
            placeholder="password"
            ref={ref}
            {...props}
        />
        <ui.InputRightElement width="4.5rem">
            <ui.Button h="1.75rem" size="sm" onClick={() => setShowPassword(s => !s)}>
                {showPassword ? 'Hide' : 'Show'}
            </ui.Button>
        </ui.InputRightElement>
    </ui.InputGroup>;
}));
