import {React, ui, icons, moment} from 'lib';

import {Loading, useRemoteData} from 'shared';
import {StockLog, Stock, Item} from 'types/stock/api/data.gen';


export const StockLogs = React.forwardRef<{
    prepend(log: StockLog): void;
    reload(): void;
}, {
    stock: Stock;
    item: Item;
    empty?: React.ReactNode;
}>(({stock, item, empty}, ref): React.ReactElement => {
    const api = useRemoteData({
        path: 'list_stock_logs',
        request: {stock_id: stock.id},
        reloadWhenFailed: true,
    });

    React.useImperativeHandle(ref, () => ({
        prepend: (log) => api.setData({items: [log, ...(api.data?.items ?? [])]}),
        reload: api.reload,
    }));

    if (!api.data) {
        return <Loading />
    }

    return <ui.Box>
        {api.data?.items.length === 0 && empty}

        {api.data?.items.map((log) => <ui.Box
            key={log.id}
            borderTop="1px"
            borderTopColor="gray.300"
        >
            <ui.Box my={2}>
                <ui.HStack alignItems="center" justifyContent="space-between">
                    <ui.Text color="gray.500" fontSize="small">
                        <icons.TimeIcon mr={2} />
                        {moment(log.created_at).format('YYYY/MM/DD HH:mm:ss')}
                    </ui.Text>

                    <ui.Text fontWeight="bold" fontSize="large">
                        {log.difference >= 0 ? '+' : ''}{log.difference}{item.unit}
                    </ui.Text>
                </ui.HStack>
            </ui.Box>
            {log.comment && <ui.Box my={2} p={3} bg="gray.50" borderRadius="8px">
                <ui.HStack>
                    <ui.Text fontSize="sm" whiteSpace="pre-line">
                        {log.comment}
                    </ui.Text>
                </ui.HStack>
            </ui.Box>}
        </ui.Box>)}
    </ui.Box>;
});
