import {React, ui, urls} from 'lib';

import {isFunction} from 'utils';
import {useSession, Req, useWriterApi, SimpleCenteredContent, ApiSpinnerDialog, ApiCompletionDialog, ApiErrorText, LoadingScreen, useForm, input, FormItem, LayoutItem, RouterLink} from 'shared';
import {User} from 'types/stock/api/data.gen';


export const useOptUser = (): User | null => {
    return useSession().data?.user ?? null;
};

const UserContext = React.createContext<User | null>(null);

export const useUser = (): User => {
    const user = React.useContext(UserContext);
    if (!user) {
        throw new Error('Can not call `useUser` outside out LoginRequired');
    }
    return user;
};

export const WithUser = ({children}: {
    children(user: User): React.ReactElement | null;
}): React.ReactElement | null => children(useUser());


export const LoginRequired = ({children}: {
    children: React.ReactNode | {(user: User): React.ReactNode};
}): React.ReactElement => {
    const {data: {user}, loading} = useSession();

    if (!user) {
        if (loading) {
            return <LoadingScreen />
        } else {
            return <LoginScreen />
        }
    }

    return <UserContext.Provider value={user}>
        {isFunction(children) ? children(user) : children}
    </UserContext.Provider>;
};


export const LoginScreen = (): React.ReactElement => {
    const session = useSession();

    const path = 'signin';
    const api = useWriterApi(path);
    const {binder, handleSubmit} = useForm<Req<typeof path>>({
        email: '',
        password: '',
    });
    const submit = handleSubmit(async (data) => {
        const {user} = await api.call(data);
        if (user) {
            session.reload();
        }
    });

    const pwresetDisclosure = ui.useDisclosure();

    return <SimpleCenteredContent title="ログイン">
        <FormItem
            label="メールアドレス"
            keyPath="email"
            error={api.error?.data}
        >
            <input.Input
                type="email"
                placeholder="email"
                {...binder.mapInputProps('email')}
            />
        </FormItem>

        <FormItem
            label="パスワード"
            keyPath="password"
            error={api.error?.data}
        >
            <input.PasswordInput
                {...binder.mapInputProps('password')}
            />
        </FormItem>

        <ApiErrorText error={api.error} />

        <LayoutItem.Group>
            <LayoutItem>
                <ui.Button onClick={submit} width="100%" isDisabled={api.loading || session.loading}>
                    ログイン
                </ui.Button>
            </LayoutItem>

            <LayoutItem>
                <ui.Text color="gray.500" textAlign="right">
                    パスワードをお忘れの方は<ui.Link onClick={pwresetDisclosure.onOpen} color="blue.500">こちら</ui.Link>
                </ui.Text>
            </LayoutItem>
        </LayoutItem.Group>

        <LayoutItem.Group>
            <LayoutItem>
                <ui.Text>
                    はじめての方は会員登録してください
                </ui.Text>
            </LayoutItem>

            <LayoutItem>
                <ui.Button
                    as={RouterLink}
                    width="100%"
                    to={urls.signup()}
                >
                    会員登録画面に移動
                </ui.Button>
            </LayoutItem>
        </LayoutItem.Group>

        <PasswordResetModal
            {...pwresetDisclosure}
            email={binder.value.email}
            onComplete={() => {
                pwresetDisclosure.onClose();
            }}
        />
    </SimpleCenteredContent>
};


export const PasswordResetModal = ({
    email,
    onComplete,
    ...props
}: {
    email: string;
    onComplete(): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'send_email_for_password_reset';
    const api = useWriterApi(path);

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        email,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">パスワードリセット</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <LayoutItem>
                        <ui.Text>
                            メールでパスワードリセット用のURLを送ります。会員登録されたメールアドレスを入力してください。
                        </ui.Text>
                    </LayoutItem>

                    <FormItem
                        label="メールアドレス"
                        keyPath="email"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('email')}
                            type="email"
                            placeholder="email"
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    メールを送る
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={() => {
                onComplete();
                reset();
                api.reset();
            }}
            title="メールを送信しました"
            message="入力したメールアドレスの受信ボックスをご確認ください"
        />
    </ui.Modal>;
};
