import {React, ui} from 'lib';

import {useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, ImageInput, imgurl, useSession, useForm, input} from 'shared';
import {Profile} from 'types/stock/api/data.gen';


export const ProfileEditionModal = ({
    profile,
    onComplete,
    ...props
}: {
    profile: Profile;
    onComplete(profile: Profile): void;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'update_profile';
    const session = useSession();
    const api = useWriterApi(path);

    const {binder, reset, handleSubmit} = useForm<Req<typeof path>>({
        profile_id: profile.id,
        icon: imgurl(profile.icon.base_url, 'cov128'),
        name: profile.name,
    });

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset, profile]);

    const submit = handleSubmit(data => api.call(data));

    return <ui.Modal {...props}>
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">プロフィールの編集</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名前"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder=""
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="full"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    更新
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                session.reload();
                onComplete(data);
                reset();
                api.reset();
            }}
        />
    </ui.Modal>;
};
