import {React, ui} from 'lib';

import {isArray} from 'utils';
import {Storage} from 'types/stock/api/data.gen';
import {S3Image, useRemoteData, Spinner, InputProps} from 'shared';
import {useWorkspace} from 'components/workspace/context';

import {StorageList as StorageListImpl} from './list';


type RequiredInput<T> = InputProps<T | null | undefined, T>;
type OptionalInput<T> = InputProps<T | null | undefined, T | null>;



export const StorageIdInput = ({value, onChange}: RequiredInput<string>): React.ReactElement => {
    return <OptionalStorageIdInputImpl
        value={value}
        onChange={(e, v) => v && onChange(e, v)}
        clearable={false}
    />;
};


export const OptionalStorageIdInput = (props: OptionalInput<string>): React.ReactElement => {
    return <OptionalStorageIdInputImpl {...props} clearable={true} />;
};


const OptionalStorageIdInputImpl = ({
    value,
    onChange,
    clearable,
}: OptionalInput<string> & {
    clearable: boolean;
}): React.ReactElement | null => {

    const {data: storage} = useRemoteData({
        path: 'preview_storage',
        request: {storage_id: value ?? ''},
        halt: !value,
    });

    if (value && value !== storage?.id) {
        return <Spinner size="lg" />
    }

    return <OptionalStorageInputImpl
        value={storage}
        onChange={(e, v) => onChange(e, v?.id ?? null)}
        clearable={clearable}
    />;
};



export const StorageInput = ({value, onChange}: RequiredInput<Storage>): React.ReactElement => {
    return <OptionalStorageInputImpl
        value={value}
        onChange={(e, v) => v && onChange(e, v)}
        clearable={false}
    />
};

export const OptionalStorageInput = (props: OptionalInput<Storage>): React.ReactElement => {
    return <OptionalStorageInputImpl {...props} clearable={true} />
};

const OptionalStorageInputImpl = ({
    value,
    onChange,
    clearable,
}: OptionalInput<Storage> & {
    clearable: boolean;
}): React.ReactElement => {

    const selectDisclosure = ui.useDisclosure();
    const workspace = useWorkspace();

    return <>
        <ui.HStack>
            <S3Image
                base={value?.icon.base_url}
                processor="cov128"
                borderRadius="full"
                width="44px"
                height="44px"
                mr={2}
            />

            <ui.Box flexBasis="100%">
                <ui.Text fontSize="xs" mr={1}>
                    {workspace.name}
                </ui.Text>

                {value && <ui.Text fontSize="md">
                    {value.name}
                </ui.Text>}

                {!value && <ui.Text fontSize="md" color="gray.500">
                    未選択
                </ui.Text>}
            </ui.Box>

            {clearable && value && <ui.Button onClick={(e) => onChange(e, null)}>
                クリア
            </ui.Button>}

            <ui.Button onClick={selectDisclosure.onOpen}>
                {value ? '変更' : '設定'}
            </ui.Button>
        </ui.HStack>

        <StorageSelectModal
            value={value}
            onChange={(e, p) => {
                selectDisclosure.onClose();
                onChange(e, p);
            }}
            {...selectDisclosure}
        />
    </>;
};



type SingleStorage = Storage | null | undefined;
type MultipleStorage = Storage[];
type StorageInputProps = InputProps<SingleStorage, Storage> | InputProps<Storage[]>;
const isMultiple = (props: StorageInputProps): props is InputProps<Storage[]> => isArray(props.value);


export const StorageSelectModal = <T extends SingleStorage | MultipleStorage>({
    value,
    onChange,
    ...props
}: InputProps<T, NonNullable<T>> & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const inputProps = {value, onChange} as StorageInputProps;

    const [storages, setStorages] = React.useState<Storage[]>([]);
    React.useEffect(() => {
        if (props.isOpen) {
            setStorages(isArray(value)
                ? value
                : value ? [value] : []);
        }
    }, [props.isOpen, value]);

    const handleSelect = (storages: Storage[]) => {
        if (isArray(value)) {
            setStorages(storages);
        } else if (storages.length > 0) {
            setStorages((old) => {
                const oldIds = old.map(s => s.id);
                return storages.filter(s => !oldIds.includes(s.id));
            });
        }
    };

    return <ui.Modal {...props} size="sm">
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">保管場所を選択</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <StorageList
                    selected={storages}
                    onSelect={handleSelect}
                />
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={(e) => isMultiple(inputProps) ? inputProps.onChange(e, storages) : inputProps.onChange(e, storages[0])}
                    isDisabled={!isArray(value) && storages.length === 0}
                >
                    確定
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>
    </ui.Modal>;
};



const StorageList = ({selected, onSelect}: {
    selected: Storage[];
    onSelect(storages: Storage[]): void;
}) => {
    const workspace = useWorkspace();

    const selectedIds = selected.map(s => s.id);
    const handleSelect = (storage: Storage) => {
        onSelect(selectedIds.includes(storage.id)
            ? selected.filter(s => s.id !== storage.id)
            : selected.concat([storage]))
    };

    return <StorageListImpl
        workspaceIds={[workspace.id]}
        selected={selectedIds}
        onSelect={handleSelect}
        empty={`${workspace.name}には保管場所が登録されていません`}
    />;
};
