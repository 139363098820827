import {React, ui} from 'lib';

import {Stock, Item} from 'types/stock/api/data.gen';
import {useWriterApi} from 'shared';



export const StockQuantityForm = ({
    stock,
    item,
    onComplete,
    size = 'md',
}: {
    stock: Stock;
    item: Item;
    onComplete(stock: Stock): void;
    size?: Props<typeof ui.Button>['size'];
}) => {
    const path = 'update_stock';
    const api = useWriterApi(path);
    const [value, setValue] = React.useState(String(stock.quantity))
    const quantity = parseFloat(value);
    const toast = ui.useToast()

    React.useEffect(() => {
        setValue(String(stock.quantity));
    }, [stock.quantity]);

    return <form
        style={{display: 'inline-block'}}
        action="/"
        onSubmit={(e) => {
            e.preventDefault();
            api.call({
                stock_id: stock.id,
                quantity,
                comment: '',
            }).then(({stock}) => {
                onComplete(stock);
                toast({
                    title: '在庫数を更新しました',
                    status: 'success',
                    duration: 1000,
                    isClosable: true,
                });
            });
        }}
    >
        <ui.HStack>
            <ui.NumberInput min={0} value={value} onChange={setValue} size={size}>
                <ui.NumberInputField width="110px" />
                <ui.NumberInputStepper>
                    <ui.NumberIncrementStepper />
                    <ui.NumberDecrementStepper />
                </ui.NumberInputStepper>
            </ui.NumberInput>

            {item.unit ? <ui.Text>
                {item.unit}
            </ui.Text> : null}

            <ui.Button
                type="submit"
                isDisabled={quantity === stock.quantity || isNaN(quantity) || api.loading}
                colorScheme="blue"
                size={size}
            >
                保存
            </ui.Button>
        </ui.HStack>
    </form>;
};
