import {React, ui} from 'lib';

import {Item, Workspace, Storage} from 'types/stock/api/data.gen';
import {useWriterApi, Req, FormItem, ApiSpinnerDialog, ApiCompletionDialog, ImageInput, generateImage, useForm, input, InputProps, S3Image} from 'shared';
import {LabelListInput} from 'components/label';
import {BarcodeListInput} from 'components/barcode';
import {StorageSelectModal} from 'components/storage/input';


export const ItemCreationModal = ({
    workspace,
    omitQuantities = false,
    defaultStorages = [],
    defaultQuantity = () => null,
    onComplete,
    onCompleteAndThen,
    initial,
    ...props
}: {
    workspace: Workspace;
    omitQuantities?: boolean;
    defaultStorages?: Storage[];
    defaultQuantity?(storage: Storage): number | null;
    onComplete(item: Item): void;
    onCompleteAndThen?(item: Item): void;
    initial?: Partial<Req<'create_item'>>;
} & Pick<ui.ModalProps, 'onClose' | 'isOpen'>): React.ReactElement => {
    const path = 'create_item';
    const api = useWriterApi(path);
    const {binder, handleSubmit, reset} = useForm<Req<typeof path>>(() => ({
        icon: generateImage(),
        name: '',
        note: '',
        unit: '',
        label_ids: [],
        urls: [],
        barcodes: [],
        quantities: defaultStorages.reduce((acc, s) => {
            acc[s.id] = defaultQuantity(s);
            return acc;
        }, {} as Keyed<number | null>),
        ...initial,
        workspace_id: workspace.id,
    }));

    React.useEffect(() => {
        if (props.isOpen) {
            reset();
        }
    }, [props.isOpen, reset]);

    const submit = handleSubmit(api.call);

    return <ui.Modal
        {...props}
    >
        <ui.ModalOverlay />
        <ui.ModalContent>
            <ui.ModalHeader textAlign="center">アイテムの登録</ui.ModalHeader>
            <ui.ModalCloseButton />

            <ui.ModalBody>
                <form onSubmit={submit}>
                    <FormItem
                        label="名称"
                        keyPath="name"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('name')}
                            placeholder="例：洗剤、歯ブラシ、コピー用紙"
                        />
                    </FormItem>

                    <FormItem
                        label="アイコン"
                        keyPath="icon"
                        error={api.error?.data}
                    >
                        <ImageInput
                            {...binder.mapInputProps('icon')}
                            width="64px"
                            height="64px"
                            borderRadius="4px"
                            nameForInitial={binder.value.name}
                        />
                    </FormItem>

                    <FormItem
                        label="メモ"
                        keyPath="note"
                        error={api.error?.data}
                    >
                        <input.Textarea
                            {...binder.mapInputProps('note')}
                            placeholder=""
                        />
                    </FormItem>

                    <FormItem
                        label="単位"
                        keyPath="unit"
                        error={api.error?.data}
                    >
                        <input.Input
                            {...binder.mapInputProps('unit')}
                            placeholder="例：個、本、箱"
                        />
                    </FormItem>

                    <FormItem label="ラベル">
                        <LabelListInput
                            {...binder.mapInputProps('label_ids')}
                        />
                    </FormItem>

                    <FormItem label="バーコード">
                        <BarcodeListInput
                            {...binder.mapInputProps('barcodes')}
                        />
                    </FormItem>

                    {!omitQuantities && <FormItem label="在庫">
                        <StockInput
                            defaultStorages={defaultStorages}
                            {...binder.mapInputProps('quantities')}
                        />
                    </FormItem>}
                </form>
            </ui.ModalBody>

            <ui.ModalFooter>
                <ui.Button onClick={props.onClose} mr={3}>キャンセル</ui.Button>
                <ui.Button
                    colorScheme="blue"
                    onClick={() => submit()}
                >
                    登録
                </ui.Button>
            </ui.ModalFooter>
        </ui.ModalContent>

        <ApiSpinnerDialog api={api} />
        <ApiCompletionDialog
            api={api}
            onOk={(data) => {
                onComplete(data);
                reset();
                api.reset();
            }}
            okAndThen="続けて登録する"
            onOkAndThen={onCompleteAndThen ? (data) => {
                onCompleteAndThen?.(data);
                reset();
                api.reset();
            } : undefined}
        />
    </ui.Modal>;
};


const StockInput = ({defaultStorages, value, onChange}: {
    defaultStorages: Storage[];
} & InputProps<{[key: string]: number | null | undefined}>) => {
    const disclosure = ui.useDisclosure();
    const storageMap = React.useRef<Keyed<Storage>>({});
    defaultStorages.forEach((s) => {
        if (!(s.id in storageMap.current)) {
            storageMap.current[s.id] = s;
        }
    });
    const storages = Object.keys(value).map(id => storageMap.current[id]);
    return <ui.Box>
        <ui.VStack>
            <ui.Button onClick={disclosure.onOpen} alignSelf="flex-start">
                場所を選択
            </ui.Button>

            {Object.entries(value).map(([id, quantity]) => <ui.HStack key={id} width="100%">
                <S3Image
                    base={storageMap?.current[id]?.icon.base_url}
                    processor="cov128"
                    borderRadius="full"
                    width="32px"
                    height="32px"
                />
                <ui.Text size="sm">
                    {storageMap?.current[id]?.name}
                </ui.Text>
                <ui.Spacer />
                <ui.Box flexShrink={0}>
                    <QuantityInput
                        value={quantity}
                        onChange={(e, v) => onChange(e, {...value, [id]: v})}
                    />
                </ui.Box>
            </ui.HStack>)}
        </ui.VStack>

        <StorageSelectModal
            value={storages}
            onChange={(e, storages) => {
                storages.forEach(s => {
                    storageMap.current[s.id] = s;
                });
                disclosure.onClose();
                onChange(e, storages.reduce((acc, s) => {
                    acc[s.id] = value[s.id] ?? 0;
                    return acc;
                }, {} as typeof value));
            }}
            {...disclosure}
        />
    </ui.Box>
};


const QuantityInput = ({value, onChange}: InputProps<number | null | undefined>) => {
    const [text, setText] = React.useState('')

    React.useEffect(() => {
        setText(String(Number.isNaN(value) ? '' : value ?? ''));
    }, [value]);

    return <ui.NumberInput
        min={0}
        value={text}
        onChange={(v, nv) => {
            setText(v);
            onChange(null, Number.isNaN(nv) ? null : nv);
        }}
        size="sm"
    >
        <ui.NumberInputField width="110px" />
        <ui.NumberInputStepper>
            <ui.NumberIncrementStepper />
            <ui.NumberDecrementStepper />
        </ui.NumberInputStepper>
    </ui.NumberInput>;
};
